import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Modal,
  Stack,
  Typography,
} from "@mui/material";

import { Bounce } from 'react-awesome-reveal';

import {
  CheckCircle as CheckCircleIcon
} from '@mui/icons-material';

import ConfettiExplosion from 'react-confetti-explosion'
// import useSound from 'use-sound';
// import tadaSound from '../sound/tada.mp3';
import GradientHeartIcon from './gradienthearticon';

interface FancyMessageBoxProperties {
  display: boolean,
  celebrate?: boolean,
  gatherFeedback?: boolean,
  onClose?: ( feedback?: string ) => void,
  heartsEarned?: number
  title: string,
  message: string,
}

const CHECK_ICON_SIZE = 90

function FancyMessageBox( props: FancyMessageBoxProperties ) {
  const [showConfetti, setShowConfetti] = useState<boolean>(false)
  // const [playTada] = useSound(tadaSound);

  useEffect(() => {
    if (props.display && props.celebrate) {
      // Uncomment if you want to have a sound.
      // NB! It's pretty loud
      // playTada();
      
      // Vibrate if vibration API supported
      if ("vibrate" in navigator) {
        navigator.vibrate(1000);
      }
      setShowConfetti(true);
      setTimeout(()=>setShowConfetti(false), 3000);
    }
  }, [props.display]);


  return (
    <Modal open={props.display} disableEscapeKeyDown={true}>
      {/* Full screen container */}
      <Box width={'100%'} height={'100%'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
        {showConfetti && <ConfettiExplosion particleCount={200} zIndex={2000}/>}
        {/* Messagebox itself */}
        <Box
          width={'320px'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{
            background: "rgb(42, 42, 42)",
            borderRadius: '28px',
            boxShadow: '0px 4px 43.2px 0px rgba(27, 27, 27, 1)'
          }}
        >
          {/* Big green checkbox */}
          <Bounce duration={500}>
            <Box
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              mb={2}
              sx={{
                marginTop: '-40px',
                backgroundColor: '#ffffff',
                width: `${Math.round(CHECK_ICON_SIZE)*0.8}px`,
                height: `${Math.round(CHECK_ICON_SIZE)*0.8}px`,
                borderRadius: "50%"
              }}
            >
              <CheckCircleIcon sx={{fontSize: `${CHECK_ICON_SIZE}px`, color: "#adff4e"}} />
            </Box>
          </Bounce>
          <Typography sx={{color: 'rgb(229, 226, 225)', fontSize: '28px'}}>{props.title}</Typography>
          <Typography
            textAlign={"center"}
            sx={{color: 'rgb(196, 199, 200)', fontSize: '14px'}}
            m={2}
          >
            {props.message}
          </Typography>
          {/* Report on earned hearts */}
          { props.heartsEarned && props.heartsEarned > 0 &&
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={'center'}
            >
              <GradientHeartIcon size={"30px"}/>
              <Typography
                textAlign={"center"}
                sx={{color: 'rgb(196, 199, 200)', fontSize: '14px'}}
                m={1}
              >
                {props.heartsEarned} hearts earned
              </Typography>
            </Box>
          }
          {/* Button */}
          <Stack direction={"row"} spacing={2} m={2} minWidth={"70%"}>
            <Button
              fullWidth
              sx={{borderColor: "rgb(215, 186, 255)", color: "rgb(215, 186, 255)"}}
              onClick={() => {
                props.onClose? props.onClose("") : console.log("Click without call");
              }}
            >
              OK
            </Button>
          </Stack>
        </Box>
      </Box>
    </Modal>
  )
}

export default FancyMessageBox;
