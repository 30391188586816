import {
  Box,
  IconButton,
  Skeleton,
  TextField,
  Typography
} from "@mui/material"

import SendIcon from '@mui/icons-material/Send';
import {isMobile} from 'react-device-detect';

import { useEffect, useRef, useState } from "react";
import BouncingDots from "./bouncingdots";

import { BOTTOM_NAVBAR_HEIGHT, MAX_WIDTH } from '../theme/gaimcontrolTheme'

export interface ChatMessage {
  message: string
  incoming: boolean
}

interface ChatMessageListProps {
  messages: ChatMessage[],
  sx?: React.ComponentProps<typeof Box>,
  onSubmit?: (message: string) => void
  disabled?: boolean
  typing?: boolean
}

function MessageBubble( props: { message: string, incoming: boolean } ) {
  return (
    <Box
      alignSelf={ props.incoming ? "start" : "end" }
      sx={{
        maxWidth: "800px",
        borderRadius: "30px 30px " + (props.incoming ? "30px 0": "0 30px"),
        paddingY: "10px",
        paddingX: "20px",
        margin: "10px",
        background: props.incoming ? "#e2e2e2e0" : "#d7baffe0",
      }}
    >
      {props.message.split('\n').map((line, index) => (
        <Typography variant="body2" key={index} sx={{color: "black"}}>{line === "" ? '\u00A0' : line}</Typography>
      ))}
    </Box>
  )
}

const messageBoxHeight: number = 40;

function ChatMessageList( props: ChatMessageListProps ) {
  const [typedText, setTypedText] = useState<string>("")
  const scrollRef = useRef<HTMLDivElement>(null);
  const textInputField = useRef<HTMLInputElement>(null);
  
  const onSubmit = () => {
    if (typedText.trim() === "") {
      return;
    }
    props.onSubmit && props.onSubmit(typedText);
    setTypedText("");
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTypedText(event.target.value);
  };

  const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      // Only send if the Enter key is pressed
      onSubmit();
    }
  };

  /* whenever the list changes we need to scroll our
    last list item into view */
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [props.messages]);

  useEffect(() => {
    if (!props.disabled) {
      setTimeout(() => textInputField.current?.focus(), 500)
    }
  }, [props.disabled])

  return (
    <Box
      height={"100%"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"beginning"}
      sx={{
        overflowY: "clip",
        width: "100%",
        maxWidth: MAX_WIDTH,
      }}
    >
      <Box
        flexGrow={1}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"beginning"}
        sx={{
          overflowY: "auto",
          backgroundImage: "url('/background_spots.png'), linear-gradient(180deg, #21263D 0%, #2D3453 100%)",
          backgroundRepeat: 'repeat-y',
          backgroundSize: "100% auto",
          backgroundAttachment: 'local',
          width: "100%",
          maxWidth: MAX_WIDTH,
          maxHeight: {
            xs: (isMobile ? `calc(100svh - 56px - 40px - ${BOTTOM_NAVBAR_HEIGHT})` : `calc(100vh - 56px - 40px - ${BOTTOM_NAVBAR_HEIGHT})`),
            sm: (isMobile ? `calc(100svh - 64px - 40px - ${BOTTOM_NAVBAR_HEIGHT})` : `calc(100vh - 64px - 40px - ${BOTTOM_NAVBAR_HEIGHT})`)
          }, 
        }}
      >
          {
            (props.messages.length > 0) ?
              props.messages.map((item, index) => {
                return (
                  <MessageBubble key={index} message={item.message} incoming={item.incoming} />
                )
              }) :
              <Skeleton variant="rectangular" width={"80%"} height={"20%"} sx={{borderRadius: "30px"}}></Skeleton>
          }
          {props.typing && <BouncingDots />}
          <Box height={0} ref={scrollRef} />
      </Box>
      <Box
        display={"flex"}
        flexDirection={"row"}
        sx={{
          height: messageBoxHeight,
          width: "100%",
          backgroundColor: "#efefef",
          position: 'fixed',
          bottom: BOTTOM_NAVBAR_HEIGHT,
          maxWidth: MAX_WIDTH,
        }}
      >
        <TextField
          variant="outlined"
          onChange={handleChange}
          onKeyDown={handleEnter}
          autoFocus
          inputRef={textInputField}
          value={typedText}
          disabled={props.disabled} 
          sx={{
            flex: 1,
            padding: 0.3,
            '& .MuiOutlinedInput-root': {
              // Targeting the input area specifically for padding adjustments
              height: '100%',
              '& input': {
                // Adjusting the text height inside the TextField
                fontSize: '16px', // For example, setting the font size to 16 pixels
                color: "#666666",
                WebkitTextStroke: "0",
                fontFamily: "Helvetica Neue"
              },
            },
          }}
          InputProps={{
            style: { padding: '1px' }
          }}
        >
        </TextField>
        <IconButton disabled={props.disabled} color="primary" onClick={onSubmit}>
          <SendIcon />
        </IconButton>
      </Box>
    </Box>
  )
}

export default ChatMessageList
