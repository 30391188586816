import {useContext, useEffect, useState} from 'react';
import './App.css';
import preval from 'preval.macro'
import axios from 'axios';

import { getCookie } from 'typescript-cookie'

import {isMobile} from 'react-device-detect';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import { ThemeProvider } from '@mui/material/styles';
import { UserProvider, UserContext } from './context/usercontext';

import {
  Box, Backdrop, CircularProgress,
} from '@mui/material';
import JourneyPage from './routes/journey';
import SessionChat from './routes/chat';
import LoginPage from './routes/login';

import type { ProtoUser, UserLogData } from './prototypes';
import GaimControlTheme from './theme/gaimcontrolTheme';

import ReactGA from "react-ga4";
import Hotjar from "@hotjar/browser"

import DemographyQuestionnaire from './routes/questionnaires/demography';
import ImpressionsQuestionnaire from './routes/questionnaires/impressions';
import DailyPage from './routes/daily';
import FirstAidPage from './routes/firstaid';
import Component404 from './routes/404';
import ListingDebtsExercise from './routes/exercises/listingdebts';
import GamblingHabitsExercise from './routes/exercises/gamhabits';
import ReasonsToGambleExercise from './routes/exercises/reasons';
import ProfilePage from './routes/profile';
import GamblingConsequencesExercise from './routes/exercises/consequences';
import AskMePage from './routes/askme';
import WordleGame from './routes/wordle';

const router = createBrowserRouter([
  {
    path: "/",
    element: <DailyPage />,
  },
  {
    path: "/aid",
    element: <FirstAidPage />
  },
  {
    path: "/chat",
    element: <SessionChat />
  },
  {
    path: "/habits",
    element: <GamblingHabitsExercise />
  },
  {
    path: "/reasons",
    element: <ReasonsToGambleExercise />
  },
  {
    path: "/debts",
    element: <ListingDebtsExercise />
  },
  {
    path: "/demo",
    element: <DemographyQuestionnaire />
  },
  {
    path: "/session_questionnaire",
    element: <ImpressionsQuestionnaire />

  },
  {
    path: "/journey",
    element: <JourneyPage />
  },
  {
    path: "/ask-me",
    element: <AskMePage />
  },
  {
    path: "/game",
    element: <WordleGame />
  },
  {
    path: "/profile",
    element: <ProfilePage />
  },
  {
    path: "/consequences",
    element: <GamblingConsequencesExercise />
  },
  {
    path: "*",
    element: <Component404 />
  }
])

function UserContent() {
  const [ loading, setLoading ] = useState(true)
  const { user, setUser, setUserLogData } = useContext(UserContext);

  useEffect(() => {
    // Load the user data if it isn't loaded yet
    if (user != null)
    {
      ReactGA.event({
        category: "Process",
        action: "User already loaded",
        label: user.name
      })
      setLoading(false)
      return;
    }
    const storedUserName = getCookie("userName")
    if (storedUserName == null)
    {
      ReactGA.event({
        category: "Process",
        action: "No cookie with the user name found",
      })
      setLoading(false)
      return;
    }

    axios.get<ProtoUser>(process.env.REACT_APP_CHAT_SERVER_URL + '/courses', {
      headers: {
          "Authorization": process.env.REACT_APP_API_TOKEN,
      },
      params: {
          userName: storedUserName
      }
    }).then(response => {
      ReactGA.event({
        category: "Process",
        action: "User data loaded from the server",
      })
      setUser(response.data);
      return axios.get<UserLogData>(process.env.REACT_APP_CHAT_SERVER_URL + "/user-log", {
        headers: {
            "Authorization": process.env.REACT_APP_API_TOKEN,
        },
        params: {
            userName: response.data.name
      }})
    }).then((data) => {
      setUserLogData(data.data.logEntries ? data.data.logEntries : {});
    }).catch(error => {
      ReactGA.event({
        category: "Process",
        action: "Error loading user data from the server",
      })
      console.log(error);
      setUser(null);
    }).finally( () => {
      setLoading(false);
    })
  }, [])

  // Letting HJ know about login / logout
  useEffect( () => {user ? Hotjar.stateChange(user.name) : Hotjar.stateChange("login")}, [user])

  return (
    <div>
      { loading &&
        <Backdrop open={loading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      }
      { user ?
        // User is logged in 
        <Box
          display={"flex"}
          flexDirection={"column"}
          sx={{
            background: "linear-gradient(180deg, #21263D 0%, #2D3453 100%)",
            userSelect: "none", // To prevent text selection when dragging stuff around in drag-and-drop mechanics
          }}
          height={isMobile ? "100svh" : "100vh"}
        >
          <RouterProvider router={router}/>
        </Box> :
        // User is not logged in
        <Box sx={{ backgroundColor: GaimControlTheme.palette.background.default }} height={isMobile ? "100svh" : "100vh"}>
          <LoginPage />
        </Box>
      }
    </div>
  )
}

function App() {
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_ID as string);
    Hotjar.init(parseInt(process.env.REACT_APP_HJ_ID || "0"), 6)
  }, [])

  return (
    <ThemeProvider theme={GaimControlTheme}>
      <UserProvider>
        <UserContent />
      </UserProvider>
      <div style={{display: "none"}}>{preval`module.exports = new Date().toUTCString();`}
      </div>
    </ThemeProvider>
  );
}

export default App;
