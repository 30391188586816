import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from "react";

interface ProcessingIndicatorProps {
  statuses: string[];
  onComplete?: () => void;
  delay?: number;
}

function ProcessingIndicator( props: ProcessingIndicatorProps ) {
  const [index, setIndex] = useState<number>(0);
  const [currentString, setCurrentString] = useState<string | null>(props.statuses[0]);
  const [visible, setVisible] = useState<boolean>(true)

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (index < (props.statuses.length - 2)) {
        setVisible(false);
        setCurrentString(props.statuses[index + 1]);
        setIndex(currentIndex => currentIndex + 1);
        setTimeout(() => setVisible(true), 500);
      } else {
        setVisible(false);
        setCurrentString(props.statuses[props.statuses.length - 1]);
        clearInterval(intervalId);
        setTimeout(() => {setVisible(true)}, 500);
        setTimeout(() => {props.onComplete && props.onComplete()}, 500 + (props.delay ? props.delay : 2000));
      }
    }, props.delay ? props.delay : 2000);

    return () => clearInterval(intervalId);
  }, [props.statuses, index]);
  
  return (
    <Stack direction={"row"} alignItems={"center"} spacing={2}>
      <CircularProgress size={25}/>
      <Stack height={'25px'} direction={"row"} alignItems={"center"}>
        <AnimatePresence>
        { visible && <motion.div
          initial={{ clipPath: 'inset(0% 100% 0% 0%)' }}
          animate={{ clipPath: 'inset(0% 0% 0% 0%)' }}
          exit={{ clipPath: 'inset(0% 100% 0% 0%)' }}
          transition={{duration: 0.5}}
        >
          <Typography>{currentString}</Typography>
        </motion.div>
        }
        </AnimatePresence>
      </Stack>
    </Stack>
  )
}

export default ProcessingIndicator;
