import { Backdrop, Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import AppbarMenu from "../../components/appbarmenu";
import { useContext, useState } from "react";
import { MAX_WIDTH } from "../../theme/gaimcontrolTheme";
import GamblingHabitMessageBox from "../../components/messageboxgamblinghabit";
import axios from "axios";
import { UserContext } from "../../context/usercontext";
import { EXERCISE_NAME_HABITS, ProtoUser } from "../../prototypes";
import FancyMessageBox from "../../components/fancymessagebox";
import { useNavigate } from "react-router-dom";

const messages: string[] = [
  "Before we set off, let's take a moment to look at what you're carrying. " +
    "Everyone brings something along on their journey. What is in your backpack?",
  "Your gambling habits are like items you bring along on your journey. Let's" +
    " think about top 3 of them: what they are, how long you've had them, and why you like them?",
]

const titles: string[] = [
  "What's your number one way of gambling?",
  "What's your second favourite way to gamble?",
  "What's your third go-to way of betting?",
]

interface GamblingHabit {
  game?: string;
  explanation?: string;
  duration?: string;
  reason?: string;
}

function GamblingHabitsExercise( ) {
  const [displayIndex, setDisplayIndex] = useState<number>(0)
  const [inputDialogVisible, setInputDialogVisible] = useState<boolean>(false)
  const [gamblingHabits, setGamblingHabits] = useState<GamblingHabit[]>([])
  const [processing, setProcessing] = useState<boolean>(false)
  const { user, setUser } = useContext(UserContext);
  const [finalDialog, setFinalDialog] = useState<boolean>(false)
  const navigate = useNavigate()

  const onGameChange = (game: string) => {
    const index = displayIndex - 2;
    const newHabit: GamblingHabit = {
      game,
      explanation: gamblingHabits[index]?.explanation,
      duration: gamblingHabits[index]?.duration,
      reason: gamblingHabits[index]?.reason
    }
    setGamblingHabits([
      ...gamblingHabits.slice(0, index),
      newHabit,
      ...gamblingHabits.slice(index + 1)
    ])
  } 

  const onExplanationChange = (explanation: string) => {
    const index = displayIndex - 2;
    const newHabit: GamblingHabit = {
      game: gamblingHabits[index]?.game,
      explanation: explanation,
      duration: gamblingHabits[index]?.duration,
      reason: gamblingHabits[index]?.reason
    }
    setGamblingHabits([
      ...gamblingHabits.slice(0, index),
      newHabit,
      ...gamblingHabits.slice(index + 1)
    ])
  } 

  const onDurationChange = (duration: string) => {
    const index = displayIndex - 2;
    const newDuration = Math.max(parseInt(duration), 0);
    const newHabit: GamblingHabit = {
      game: gamblingHabits[index]?.game,
      explanation: gamblingHabits[index]?.explanation,
      duration: `${newDuration}`,
      reason: gamblingHabits[index]?.reason
    }
    setGamblingHabits([
      ...gamblingHabits.slice(0, index),
      newHabit,
      ...gamblingHabits.slice(index + 1)
    ])
  }

  const onReasonChange = (reason: string) => {
    const index = displayIndex - 2;
    const newHabit: GamblingHabit = {
      game: gamblingHabits[index]?.game,
      explanation: gamblingHabits[index]?.explanation,
      duration: gamblingHabits[index]?.duration,
      reason: reason
    }
    setGamblingHabits([
      ...gamblingHabits.slice(0, index),
      newHabit,
      ...gamblingHabits.slice(index + 1)
    ])
  }

  const inputMissing = () => {
    const habit = gamblingHabits[displayIndex - 2]
    if (habit === undefined) return true;
    if (habit.game === undefined) return true;
    if (habit.duration === undefined) return true;
    if (habit.reason === undefined || habit.reason === "") return true;
    if (habit.game === "❓ Other" && (habit.explanation === undefined || habit.explanation === "")) return true;
    return false;
  }

  const onNext = () => {
    setInputDialogVisible(false)
    setTimeout( () => {
      setGamblingHabits(gamblingHabits.concat({}))
      setDisplayIndex(displayIndex + 1)
      setInputDialogVisible(true)
    }, 500)
  }

  const onDone = () => {
    setInputDialogVisible(false)
    setProcessing(true)
    axios.put(process.env.REACT_APP_CHAT_SERVER_URL + "/exercise", gamblingHabits,
    {
      headers: {
        "Authorization": process.env.REACT_APP_API_TOKEN,
        "Content-Type": "application/json"
      },
      params: {
        userName: user?.name,
        exerciseName: encodeURIComponent(EXERCISE_NAME_HABITS),
      }
    }).then(
      response => {
        return axios.get<ProtoUser>(process.env.REACT_APP_CHAT_SERVER_URL + `/courses?userName=` + user?.name, {
          headers: {
            "Authorization": process.env.REACT_APP_API_TOKEN
          }      
        })
    }).then(
      response => {
        setUser(response.data)
        setFinalDialog(true)
    }).catch(error => {
      console.log(error)
    }).finally(() => {
      setProcessing(false)
    })
  }

  return (
  <Stack
    alignSelf={"center"}
    alignItems={"center"}
    justifyContent={"center"}
    height={"100%"}
    maxWidth={MAX_WIDTH}
  >
    <AppbarMenu />
    <Typography variant="h4">Review your load</Typography>
    <Stack alignItems={"center"} justifyContent={"center"} flexGrow={1} px={2} sx={{overflowY: "auto"}}>
      <Box component="img" maxHeight={"300px"} maxWidth={"300px"} src="/exercises/reasons/backpack.svg" my={6}/>
      <Box
        sx= {{
          border: '',
          backgroundColor: '#E2E2E2',
          borderRadius: '20px'
        }}
        p={2}
      >
        <Typography color={"black"} variant="body1">{messages[Math.min(displayIndex, messages.length - 1)]}</Typography>
      </Box>
    </Stack>
    <Button
      variant="contained"
      onClick={() => { displayIndex === 1 && setInputDialogVisible(true); setDisplayIndex(displayIndex + 1)}}
      sx={{marginY: 2, paddingX: '60px'}}
    >
      Next
    </Button>
    <GamblingHabitMessageBox
      title={titles[Math.min(displayIndex - 2)]}
      open={inputDialogVisible}
      game={gamblingHabits[displayIndex - 2]?.game}
      explanation={gamblingHabits[displayIndex - 2]?.explanation}
      duration={gamblingHabits[displayIndex - 2]?.duration}
      reason={gamblingHabits[displayIndex - 2]?.reason || ""}
      onGameChange={onGameChange}
      onExplanationChange={onExplanationChange}
      onDurationChange={onDurationChange}
      onReasonChange={onReasonChange}
      onNext={onNext}
      onDone={onDone}
      disableDone={inputMissing()}
      disableNext={inputMissing() || (displayIndex >= 4)}
    />
    <Backdrop
      sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}
      open={processing}
    >
      <CircularProgress/>
    </Backdrop>
    <FancyMessageBox
        message={"Thinking about what items you carry, and what makes you carry those items, is one step towards changing your load"}
        title='Well done.'
        display={finalDialog}
        heartsEarned={user?.blocks[user.currentBlock - 1]?.pointsType1 || 0}
        onClose={() => {
          setFinalDialog(false);
          navigate("/")
        }}
      />
  </Stack>)
}

export default GamblingHabitsExercise;
