import { useContext, useEffect, useRef, useState } from 'react';

import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Box,
    Popover
} from '@mui/material';
import { UserContext } from '../context/usercontext';
import GradientHeartIcon from './gradienthearticon';
import { MAX_WIDTH } from '../theme/gaimcontrolTheme';
import axios from 'axios';
import { ProtoUser } from '../prototypes';
import DateToString from '../helpers/dateformatting';

function Greetings( nickname: string | undefined ) {
  let hours = new Date().getHours();
  return `${(hours < 12 ? "Good morning" : hours < 17 ? "Good afternoon" : "Good evening")}` +
    (nickname === undefined || nickname === "" ? "!" : `, ${nickname}!`) 
}
  
interface AppbarMenuProps {
    title?: string
}

function AppbarMenu( props: AppbarMenuProps ) {
  const [isUserMenuOpen, setUserMenuOpen] = useState(false);
  const { user, userLogData, setUser } = useContext(UserContext);
  const [greeting, setGreeting] = useState<string>(Greetings(user?.nickname))
  const [reportingStreak, setReportingStreak] = useState<number>(0)
  const badgeRef = useRef<null | HTMLDivElement>(null)

  const changeName = () => {
    const newNickname = prompt("How would you like to be called?")

    if (user && newNickname !== null) {
      const newUser = user
      newUser.nickname = newNickname
      setGreeting(Greetings(user?.nickname))
      axios.patch<ProtoUser>(process.env.REACT_APP_CHAT_SERVER_URL + '/users', { nickname: newNickname }, {
        headers: {
            "Authorization": process.env.REACT_APP_API_TOKEN,
        },
        params: {
            userName: user.name
        }}
      ).then(response => {
        setUser(response.data)
      })
    }
  }

  useEffect(()=>{
    if (userLogData === null || user === null || user.created === undefined) {
        return;
    }

    let streak = 1
    let date = new Date()
    let stringDate = DateToString(date)
    const created = DateToString(new Date(user.created))

    while (stringDate !== created) {
      date.setDate(date.getDate() - 1)
      stringDate = DateToString(date)
      if (userLogData[stringDate] === undefined) {
        break
      }
      streak++
    }
    setReportingStreak(streak)
  })

  return (
    <Box>
      <AppBar
        style={{
            background: "transparent",
            boxShadow: 'none',
            alignSelf: 'center',
        }}
      >
        <Toolbar sx={{width: '100%', maxWidth: MAX_WIDTH, alignSelf: 'center'}}>
          <Box
            width={'50px'}
            height={'50px'}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            sx={{
              backgroundImage: "url('/octagon.svg')",
              backgroundSize: 'contain',
            }}
            ref={badgeRef}
            onClick={(event: React.MouseEvent<HTMLDivElement>) => setUserMenuOpen(true)}
          >
            <Typography m={0} p={0} textAlign={'center'} variant='body2' color={'#370085'}>{reportingStreak}</Typography>
          </Box>
          <Popover
            open={isUserMenuOpen}
            anchorEl={badgeRef.current}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            onClose={()=>setUserMenuOpen(false)}
          >
            <Typography textAlign={'center'} variant='body2'>
              Number of days (including today) in a row you've logged both your mood and gambling.
            </Typography>
          </Popover>
          {/* <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => setUserMenuOpen(true)}
          >
              <AccountCircleIcon sx={{fontSize: '40px', color: 'rgb(192, 158, 240)', ml: 1}}/>
          </IconButton> */}

          <Typography
            key={user?.nickname}
            onClick={changeName}
            variant="h6"
            sx={{
              flexGrow: 1,
              maxWidth: MAX_WIDTH,
              cursor: 'pointer'
            }}
          >
            {greeting}
          </Typography>

          <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit">
              <GradientHeartIcon size={"24px"} />
              <Typography variant='body1' sx={{color: "#ffffff", marginLeft: "4px"}}>{user?.pointsType1}</Typography>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </Box>
  )
}

export default AppbarMenu;