import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LogoComponent from "../components/LogoComponent";
import { MAX_WIDTH } from "../theme/gaimcontrolTheme";

interface Component404Props {
  homeButton?: boolean
}

function Component404( props: Component404Props ) {
  const navigate = useNavigate()

  return <Stack
    width={"100%"}
    maxWidth={MAX_WIDTH}
    alignSelf={"center"}
    display={"flex"}
    flexDirection={"column"}
    alignItems={"center"}
    flexGrow={1}
    sx={{overflowY: "auto"}}
  >
      <LogoComponent />
      <Grid container width={"100%"} spacing={3}>
        <Grid item md={6} sm={12} order={{md: 1, sm: 2, xs: 2}}>
          <Typography mx={10} mt={10} variant="h3">Oops!</Typography>
          <Typography mx={10} mt={5} variant="h4">Nothing to see here</Typography>
          <Typography mx={10} mt={5} variant="body1">Not all those who wander, are lost.
          Take a step back to move forward.</Typography>
          <Button variant="contained" onClick={() => navigate("/")} sx={{marginX: 10, marginY: 5}}>Return Home</Button>
        </Grid>
        <Grid item md={6} sm={12} xs={12} order={{md: 2, sm: 1, xs: 1 }} display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <Box
              sx={{
                backgroundImage: "url(/wanderer.svg)",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                borderRadius: "500px",
                border: "1px solid #EAF2F0",
                height: "300px",
                width: "300px",
              }}
            />
        </Grid>
      </Grid>
  </Stack>
}

export default Component404;
