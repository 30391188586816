import { useContext, useState } from 'react';
import { setCookie } from 'typescript-cookie'

import { TextField, Alert, Button, Backdrop, CircularProgress, Link, Stack } from '@mui/material';
import Box from '@mui/material/Box';

import { UserContext } from '../context/usercontext';
import { MAX_WIDTH } from '../theme/gaimcontrolTheme'
import type { ProtoUser, UserLogData } from '../prototypes';

import axios from 'axios';
import LogoComponent from '../components/LogoComponent';

const LoginPage: React.FC = () => {
  const { setUser, setUserLogData } = useContext(UserContext);
  const [ alert, setAlert ] = useState(false)
  const [ code, setCode ] = useState<string>("")
  const [ processing, setProcessing ] = useState(false)

  const onSubmit = () => {
    setProcessing(true)
    let userName = code.trim().toLowerCase()

    axios.get<ProtoUser>(process.env.REACT_APP_CHAT_SERVER_URL + `/courses?userName=` + userName, {
      headers: {
        "Authorization": process.env.REACT_APP_API_TOKEN,
      }      
    }).then(response => {
      setUser(response.data);
      setCookie("userName", userName, {sameSite: "Strict"})
    }).then(() => {
      return axios.get<UserLogData>(process.env.REACT_APP_CHAT_SERVER_URL + "/user-log", {
        headers: {
            "Authorization": process.env.REACT_APP_API_TOKEN,
        },
        params: {
            userName: userName
      }})
    }).then((data) => {
      setUserLogData(data.data.logEntries ? data.data.logEntries : {});
    }).catch(error => {
      setAlert(true)
    }).finally(() => {
      setProcessing(false)
    })
  }

  const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      alert && setAlert(false)
      onSubmit()
    }
  };

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      sx={{
        height: '100%',
        paddingX: 2,
      }}
    >

      { alert &&
        <Alert
          variant="filled"
          severity="error"
          onClose={() => setAlert(false)}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            boxSizing: 'border-box',
          }}
        >
          Wrong code. If you would like to have a reminder, please send us an email to <Link
            href="mailto:contact@gaimcontrol.com?subject=Access%20code%20reminder%20request"
            target="_blank"
            rel="noopener noreferrer"
            color='#d0d0d0'>
            contact@gaimcontrol.com
          </Link>
        </Alert>
      }
      <Box flexGrow={1}/>
      <Stack direction={"row"}>
        <LogoComponent></LogoComponent>
      </Stack>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignContent: 'center',
          flexWrap: 'wrap',
          width: '100%',
          mt: 4,
          maxWidth: MAX_WIDTH,
        }}
      >
        <TextField
            required
            id="outlined-basic"
            label="Please enter the code"
            variant="outlined"
            onKeyDown={handleEnter}
            onChange={(e) => setCode(e.target.value)}
            fullWidth
            sx={{mb: 2}}
        />
        <Button variant='contained' sx={{marginY: 2}} fullWidth onClick={onSubmit}>
          Submit
        </Button>
      </Box>
      <Box flexGrow={1}/>
      <Box flexGrow={1}/>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1
        }}
        open={processing}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  )
}

export default LoginPage;