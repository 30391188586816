import React, { createContext, useState } from 'react';
import type {
    ProtoUser,
    UserLogEntry
} from '../prototypes';

interface UserContextType {
    user: ProtoUser | null;
    userLogData: {[key: string]: UserLogEntry} | null;
    setUser: (id: ProtoUser | null) => void;
    setUserLogData: (data: {[key: string]: UserLogEntry} | null) => void;
}

export const UserContext = createContext<UserContextType>({
    user: null,
    userLogData: null,
    setUser: (userRecord) => { },
    setUserLogData: (data) => { },
});

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [user, setUser] = useState<ProtoUser | null>(null);
    const [userLogData, setUserLogData] = useState<{[key: string]: UserLogEntry} | null>(null);

    return (
    <UserContext.Provider value={{ user, userLogData, setUser, setUserLogData }}>
        {children}
    </UserContext.Provider>
    );
};
