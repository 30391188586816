import { Backdrop, Box, Button, CircularProgress, Divider, Stack, Typography } from "@mui/material";
import { useContext, useState } from "react";

import {
  Person as PersonIcon,
  AccountBalance as AccountBalanceIcon,
  PostAdd as PostAddIcon
}from '@mui/icons-material';
import DebtItemMessageBox from "../../components/debtitemmessagebox";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { UserContext } from "../../context/usercontext";
import AppbarMenu from "../../components/appbarmenu";
import FancyMessageBox from "../../components/fancymessagebox";
import { EXERCISE_NAME_DEBTS, ProtoUser } from "../../prototypes";

interface DebtListItem {
  type?: 'organization' | 'person'
  creditor: string;
  amount: number;
}

type DebtList = DebtListItem[];

interface DebtListItemComponentProps {
  divider?: boolean;
  item: DebtListItem;
  index: number;
  onClick?: ( index: number ) => void;
}

function DebtListItemComponent( props: DebtListItemComponentProps ) {
  return (
    <div>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        onClick={() => props.onClick && props.onClick(props.index)}
        sx={{
          cursor: 'pointer',
        }}
      >
        <Stack direction={"row"} sx={{alignItems: "center"}} gap={1}>          
          {props.item.type === 'organization' ? <AccountBalanceIcon color='info'/> : <PersonIcon color='info'/>}
          <Typography variant="body1">
            {props.item.creditor}
          </Typography>
        </Stack>
        <Typography variant="body1">{new Intl.NumberFormat().format(props.item.amount)}</Typography>
      </Stack>
      { props.divider && <Divider sx={{marginY: "10px"}} />}
    </div>
  )
}

interface DebtListComponentProps {
  headerText?: string;
  subheaderText?: string;
  debtList: DebtList;
  onAdd?: () => void;
  onEdit?: ( item: number ) => void
}

function DebtListComponent( props: DebtListComponentProps ) {
  return (
  <Stack
    sx={{
      width: "100%",
      overflowY: 'auto',
    }}
  >
    {props.headerText && (<Typography variant="h4" gutterBottom>{props.headerText}</Typography>)}
    {props.subheaderText && (<Typography variant="body1" mb={3}>{props.subheaderText}</Typography>)}
    {
      props.debtList.map((item, index) => (
        <DebtListItemComponent index={index} key={index} item={item} divider={true} onClick={props.onEdit} />
      ))
    }
    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
        <Typography fontWeight={"bold"} variant="body1">Entries: {props.debtList.length}</Typography>
        <Typography fontWeight={"bold"} variant="body1">
          Total: {new Intl.NumberFormat().format(props.debtList.reduce((total, current) => total + current.amount, 0))}
        </Typography>
    </Stack>
    <Stack direction={"row"} sx={{marginTop: 2}}>
      <Box flexGrow={1}/>
      <Button variant="contained" size="large" onClick={props.onAdd} startIcon={<PostAddIcon/>}>Add</Button>
    </Stack>
  </Stack>)
}

function ListingDebtsExercise( ) {
  const [debtItemDialogOpen, setDebtItemDialogOpen] = useState<boolean>(false)
  const [debtItemType, setDebtItemType] = useState<'person' | 'organization' | undefined>(undefined)
  const [creditor, setCreditor] = useState<string>('')
  const [amount, setAmount] = useState<number>(0)
  const [selectedItem, setSelectedItem] = useState<number | undefined>(undefined)
  const [debtList, setDebtList] = useState<DebtList>([])
  const [processing, setProcessing] = useState<boolean>(false)
  const [finalDialog, setFinalDialog] = useState<boolean>(false)
  const navigate = useNavigate()
  const { user, setUser } = useContext(UserContext);

  const onItemCancel = () => {
    setDebtItemDialogOpen(false);
    setDebtItemType(undefined);
    setAmount(0);
    setSelectedItem(undefined)
    setCreditor('');
  }

  const onItemSave = () => {
    const type = (debtItemType === undefined ? 'organization' : debtItemType) 
    if (selectedItem === undefined)
    {
      setDebtList([...debtList, {creditor: creditor, amount: amount, type: type}])
    } else {
      const newDebtList = [...debtList]
      newDebtList[selectedItem] = {creditor: creditor, amount: amount, type: type}
      setDebtList(newDebtList)
    }
    onItemCancel();
  }

  const onItemDelete = () => {
    const newDebtList = [...debtList]
    newDebtList.splice(selectedItem!, 1)
    setDebtList(newDebtList)
    onItemCancel()
  }

  const onEdit = ( item: number ) => {
    setDebtItemType(debtList[item].type)
    setCreditor(debtList[item].creditor)
    setAmount(debtList[item].amount)
    setSelectedItem(item)
    setDebtItemDialogOpen(true)
  }

  const onDone = () => {
    setProcessing(true)
    axios.put(process.env.REACT_APP_CHAT_SERVER_URL + "/exercise", debtList,
    {
      headers: {
        "Authorization": process.env.REACT_APP_API_TOKEN,
        "Content-Type": "application/json"
      },
      params: {
        userName: user?.name,
        exerciseName: encodeURIComponent(EXERCISE_NAME_DEBTS),
      }
    }).then(
      response => {
        return axios.get<ProtoUser>(process.env.REACT_APP_CHAT_SERVER_URL + `/courses?userName=` + user?.name, {
          headers: {
            "Authorization": process.env.REACT_APP_API_TOKEN
          }      
        })
    }).then(
      response => {
        setUser(response.data)
        setFinalDialog(true)
    }).catch(error => {
      console.log(error)
    }).finally(() => {
      setProcessing(false)
    })
  }

  return (
    <Stack
      alignSelf={"center"}
      alignItems={"center"}
      justifyContent={"center"}
      height={"100%"}
      px={2}
      pb={2}
    >
      <AppbarMenu />
      <DebtListComponent
        headerText='Facing Your Financial Debts'
        subheaderText='Start listing your creditors and amount owed to them by clicking the "Add" button.'
        debtList={debtList}
        onAdd={() => setDebtItemDialogOpen(true)}
        onEdit={onEdit}
      />
      <Box flexGrow={1} />
      <Stack my={2} direction={"row"} width={"100%"} spacing={2}>
        <Box flexGrow={1} />
        <Button
          color="warning"
          variant="contained"
          sx={{paddingX: '50px'}}
          onClick={() => navigate('/')}
        >
          Cancel
        </Button>
        <Button
          color={debtList.length > 0 ? "success": "info"}
          variant="contained"
          sx={{paddingX: '50px'}}
          onClick={onDone}
        >
          { debtList.length > 0 ? 'Done' : 'Skip' }
        </Button>
      </Stack>
      <DebtItemMessageBox
        open={debtItemDialogOpen}
        type={debtItemType}
        amount={amount}
        creditor={creditor}
        delete={selectedItem !== undefined}
        onSelectType={setDebtItemType}
        onChangeCreditor={setCreditor}
        onChangeAmount={setAmount}
        onCancel={onItemCancel}
        onSave={onItemSave}
        onDelete={onItemDelete}
      />
      <FancyMessageBox
        message={"Listing your debts is just the beginning, but it's an important one. I am here to help " +
          "you work through this and come out stronger on the other side."}
        title='Well done.'
        display={finalDialog}
        heartsEarned={user?.blocks[user.currentBlock - 1]?.pointsType1 || 0}
        onClose={() => {
          setFinalDialog(false);
          navigate("/")
        }}
      />
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={processing}
      >
        <CircularProgress/>
      </Backdrop>

    </Stack>
  );
}

export default ListingDebtsExercise;