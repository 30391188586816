interface Quote {
    text: string;
    author: string;
  }
  
  let QuotesOfTheDay: Quote[] = [
    { text: "The best way out is always through.", author: "Robert Frost"},
    { text: "You are never too old to set another goal or to dream a new dream.", author: "C.S. Lewis"},
    { text: "What you get by achieving your goals is not as important as what you become by achieving your goals.", author: "Zig Ziglar"},
    { text: "The only place where success comes before work is in the dictionary.", author: "Vidal Sassoon"},
    { text: "Perseverance is not a long race; it is many short races one after the other.", author: "Walter Elliot"},
    { text: "The future depends on what you do today.", author: "Mahatma Gandhi"},
    { text: "If you can dream it, you can do it.", author: "Walt Disney"},
    { text: "You are braver than you believe, stronger than you seem, and smarter than you think.", author: "A.A. Milne"},
    { text: "Hardships often prepare ordinary people for an extraordinary destiny.", author: "C.S. Lewis"},
    { text: "You don't have to be great to start, but you have to start to be great.", author: "Zig Ziglar"},
    { text: "It always seems impossible until it's done.", author: "Nelson Mandela"},
    { text: "Small deeds done are better than great deeds planned.", author: "Peter Marshall"},
    { text: "The best revenge is massive success.", author: "Frank Sinatra"},
    { text: "Life is 10% what happens to us and 90% how we react to it.", author: "Charles R. Swindoll"},
    { text: "The only way to achieve the impossible is to believe it is possible.", author: "Charles Kingsleigh (Alice in Wonderland)"},
    { text: "Success is the sum of small efforts, repeated day-in and day-out.", author: "Robert Collier"},
    { text: "What we achieve inwardly will change outer reality.", author: "Plutarch"},
    { text: "Strength does not come from physical capacity. It comes from an indomitable will.", author: "Mahatma Gandhi"},
    { text: "The man who moves a mountain begins by carrying away small stones.", author: "Confucius"},
    { text: "The only journey is the one within.", author: "Rainer Maria Rilke"},
    { text: "Dream big and dare to fail.", author: "Norman Vaughan"},
    { text: "The best way to find yourself is to lose yourself in the service of others.", author: "Mahatma Gandhi"},
    { text: "The only thing standing between you and your goal is the story you keep telling yourself as to why you can't achieve it.", author: "Jordan Belfort"},
    { text: "You must do the thing you think you cannot do.", author: "Eleanor Roosevelt"},
    { text: "Do not wait to strike till the iron is hot; but make it hot by striking.", author: "William Butler Yeats"},
    { text: "To be a champ, you have to believe in yourself when nobody else will.", author: "Sugar Ray Robinson"},
    { text: "The only person you are destined to become is the person you decide to be.", author: "Ralph Waldo Emerson"},
    { text: "Challenges are what make life interesting and overcoming them is what makes life meaningful.", author: "Joshua J. Marine"},
    { text: "The greatest glory in living lies not in never falling, but in rising every time we fall.", author: "Nelson Mandela"},
    { text: "What you do today can improve all your tomorrows.", author: "Ralph Marston"},
    { text: "Believe in yourself and all that you are. Know that there is something inside you that is greater than any obstacle.", author: "Christian D. Larson"},
    { text: "The only limit to our realization of tomorrow will be our doubts of today.", author: "Franklin D. Roosevelt"},
  ]

  function getQuoteOfTheDay( date: Date) : Quote {
    return QuotesOfTheDay[Math.floor((date.getTime())/8.64e7) % QuotesOfTheDay.length];
  }

  export default getQuoteOfTheDay;
