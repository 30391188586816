import React, { useContext, useState } from 'react';
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox,
  TextField,
  Box,
  Stack,
  Typography,
  LinearProgress,
  Slide,
  Backdrop,
  CircularProgress
} from '@mui/material';
import { MAX_WIDTH, TOP_BACKGROUND_GRADIENT } from '../../theme/gaimcontrolTheme';

import { Bounce } from 'react-awesome-reveal';
import axios from 'axios';
import { UserContext } from '../../context/usercontext';
import { getCookie } from 'typescript-cookie';
import { EXERCISE_NAME_DEMOGRAPHY, ProtoUser } from '../../prototypes';
import FancyMessageBox from '../../components/fancymessagebox';
import { useNavigate } from 'react-router-dom';
import ProcessingIndicator from '../../components/processingindicator';

interface Question {
  type: 'single' | 'multiple';
  question: string;
  options: string[];
  allowOther?: boolean;
  otherName?: string;
  clarification?: string;
}

interface Response {
  question: string;
  responses: string[];
  otherText?: string;
}

const questions: Question[] = [
  { type: 'single', question: 'Pick your gender', options: ['Female', 'Male', 'Other'], allowOther: false },
  { type: 'single', question: 'How old are you?', options: ['Under 20', '20-29', '30-39', '40-49', 'Over 50'], allowOther: false },
  { type: 'multiple', question: 'How did you gamble?', options: ['Sports betting', 'Online casino', 'Slot machines', 'Land casino', 'Trading/Crypto'], allowOther: true, clarification: "What was it?" },
  { type: 'single', question: 'Did you try any application to stop gambling?', options: ['No'], allowOther: true, otherName: "Yes", clarification: "Which application(s)?" },
  { type: 'single', question: 'Have you tried therapy or counselling before?', options: ['No', 'Yes'] },
];

const Questionnaire: React.FC = () => {
  const { user, setUser } = useContext(UserContext);
  const [index, setIndex] = useState(0);
  const [responses, setResponses] = useState<{[key: number]: string[]}>({});
  const [otherText, setOtherText] = useState<{[key: number]: string}>({});
  const [direction, setDirection] = useState<"left"|"right">("left");
  const [show, setShow] = useState<boolean>(true);
  const [finalDialog, setFinalDialog] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const navigate = useNavigate();

  // Handle the change in the answer
  const handleChange = (changeIndex: number, value: string, type: 'single' | 'multiple') => {
    setResponses(prev => {
      const updated = { ...prev };
      if (type === 'single') {
        updated[changeIndex] = [value];
      } else {
        if (!updated[changeIndex]) updated[changeIndex] = [];
        const optionIndex = updated[changeIndex].indexOf(value);
        if (optionIndex === -1) {
          updated[changeIndex].push(value);
        } else {
          updated[changeIndex].splice(optionIndex, 1);
        }
      }
      return updated;
    });
  };

  // Check, if the user can proceed to the next question (answered to the current one)
  const canProceed = (changeIndex: number) => {
    const response = responses[changeIndex];
    if (questions[changeIndex].allowOther && response?.includes('Other') && !otherText[changeIndex]) {
      return false;
    }
    return response && (response.length > 0 || (questions[changeIndex].allowOther && otherText[changeIndex]?.trim().length > 0));
  };

  // Save the questionnaire replies
  const saveReplies = () => {
    setProcessing(true)
    setSaving(true);
    var responsesToSave: Response[] = []

    questions.map((question, index) => {
      responsesToSave.push({
        question: question.question,
        responses: responses[index],
        otherText: otherText[index]
      })
      return question;
    })

    console.debug("Saving the exercise...")
    axios.put(process.env.REACT_APP_CHAT_SERVER_URL + "/exercise", JSON.stringify(responsesToSave),
      {
        headers: {
          "Authorization": process.env.REACT_APP_API_TOKEN,
          "Content-Type": "application/json"
        },
        params: {
          userName: user?.name,
          exerciseName: encodeURIComponent(EXERCISE_NAME_DEMOGRAPHY),
        }
      },
    ).then(
      response => {
        if (response.status === 200) {
          console.debug("Successful.")
          // setCelebration(true)
        }
        const code = getCookie("userName")
        console.debug("Reloading the user data...")
        return axios.get<ProtoUser>(process.env.REACT_APP_CHAT_SERVER_URL + `/courses?userName=` + code, {
          headers: {
            "Authorization": process.env.REACT_APP_API_TOKEN
          }      
        })
    }).then(response => {
      console.debug("Successful.")
      setFinalDialog(true);
      setUser(response.data);
    }).catch(error => {
      console.log("Error saving the exercise.")
      // setErrorDialogOpen(true)
    }).finally( () => {
      setSaving(false);
    })
  }

  return (
    <Box bgcolor={"#21263D"} width={"100%"} height={"100%"} display={"flex"} justifyContent={"center"}>
      <Stack width={"100%"} maxWidth={MAX_WIDTH} alignContent={"center"} spacing={2} display={"flex"} height={"100%"} alignItems={"center"}>
        <Typography variant='h3' pt={3} px={2}>Let's Tailor Your Journey</Typography>
        <LinearProgress variant='determinate' value={((index) / (questions.length - 1)) * 100} sx={{height: 4, width: "100%"}}/>

        <Box sx={{width: "100%", background: TOP_BACKGROUND_GRADIENT, overflowY: "auto"}} >
          <Slide direction={direction} in={show} timeout={250}><Box display={"flex"} alignItems={"center"} flexDirection={"column"}>
            <Typography variant='h5' my={5} mx={2}>{questions[index].question}</Typography>
            <FormControl component="fieldset" sx={{minWidth: "50%"}}>
              {
                questions[index].type === 'single' ? (
                  <RadioGroup
                  value={responses[index] ? responses[index][0] : ''}
                  onChange={(e) => handleChange(index, e.target.value, questions[index].type)}
                >
                  {questions[index].options.map(option => (
                    <FormControlLabel key={option} value={option} control={<Radio />} label={option} />
                  ))}
                  {questions[index].allowOther && (
                    <div>
                    <FormControlLabel value="Other" control={<Radio />} label={questions[index].otherName}/>
                    { responses[index] && responses[index][0] === 'Other' && (
                      <Bounce duration={500}>
                        <TextField
                          variant="standard"
                          value={otherText[index] || ''}
                          placeholder={questions[index].clarification}
                          onChange={(e) => setOtherText({ ...otherText, [index]: e.target.value })}
                        />
                      </Bounce>
                    )}
                    </div>
                  )}
                </RadioGroup>
                ) : (
                  <Stack>
                    {questions[index].options.map(option => (
                      <FormControlLabel
                        key={option}
                        control={
                          <Checkbox
                            checked={responses[index]?.includes(option) || false}
                            onChange={() => handleChange(index, option, questions[index].type)}
                          />
                        }
                        label={option}
                      />
                    ))}
                  {questions[index].allowOther && (
                    <Stack>
                      <FormControlLabel control={<Checkbox
                        checked={responses[index]?.includes('Other') || false}
                        onChange={() => handleChange(index, 'Other', questions[index].type)}
                      />} 
                      label="Other"
                      />
                      {responses[index]?.includes('Other') && (
                        <Bounce duration={500}>
                          <TextField
                            variant="standard"
                            value={otherText[index] || ''}
                            placeholder={questions[index].clarification}
                            onChange={(e) => setOtherText({ ...otherText, [index]: e.target.value })}
                          />
                        </Bounce>
                      )}
                    </Stack>
                  )}
                </Stack>
                )
              }
            </FormControl>
          </Box></Slide>
        </Box>
        <Box flexGrow={1}></Box>
        <Box display={"flex"} flexDirection={"row"} width={"100%"} py={2}>
          <Button
            variant="outlined"
            fullWidth
            onClick={() => {
              setDirection("left");
              setShow(false);
              setTimeout(() => {setIndex(prev => Math.max(prev - 1, 0)); setDirection("right"); setShow(true)}, 500);
            }}
            disabled={index === 0}
            sx={{margin: 2}}
          >
            Previous
          </Button>
          <Button
            variant='contained'
            color={index < questions.length - 1 ? 'primary' : 'success'}
            fullWidth
            onClick={() => {
              if (index < questions.length - 1) {
                setDirection("right");
                setShow(false);                
                setTimeout(() => {setIndex(prev => prev + 1); setDirection("left"); setShow(true)}, 500);
              } else {
                // Handle the "Finish" action here
                saveReplies();
              }
            }}
            disabled={!canProceed(index)}
            sx={{margin: 2}}
          >
            {index < questions.length - 1 ? 'Next' : 'Finish'}
          </Button>
        </Box>
      </Stack>
      <FancyMessageBox
        message="Now, we can start building the tailored program for you."
        title='Keep going!'
        display={finalDialog && !processing}
        celebrate={true}
        gatherFeedback={true}
        heartsEarned={user?.blocks[user.currentBlock - 1]?.pointsType1 || 0}
        onClose={() => {
          setFinalDialog(false);
          navigate("/")
        }}
      />
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={processing || saving}
      >
        <ProcessingIndicator
          statuses={[
            "Analysing data...       ",
            "Building curriculum...  ",
            "Finalizing...           "
          ]}
          onComplete={()=>setProcessing(false)}
        />
      </Backdrop>
    </Box>
  );
};

export default Questionnaire;