import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { UserLogEntry } from "../prototypes";
import DateToString from "../helpers/dateformatting";

import {
  CheckCircle as CheckCircleIcon,
  Adjust as AdjustIcon,
  NoteAdd as NoteAddIcon,
  AddReaction as AddReactionIcon,
} from '@mui/icons-material';

const FUTURE_DAYS = 8

function areDatesEqual( date1: Date, date2: Date ): boolean {
  return (date1.getDate() === date2.getDate()) &&
  (date1.getMonth() === date2.getMonth()) &&
  (date1.getFullYear() === date2.getFullYear())
}

interface CalendarStripTileProps {
  index?: number,
  initializing?: boolean,
  date: Date,
  selected?: boolean,
  userLogData?: UserLogEntry,
  onClick?: ()=> void,
}

function CalendarStripTile( props: CalendarStripTileProps ) {
  return (
  <Stack
    padding={1}
    onClick={props.onClick}
    sx={
      props.selected ? {
        backgroundColor: (theme) => theme.palette.text.secondary,
        boxShadow: "0px 0px 0px 4px #FFFFFF40",
        borderRadius: "20px"
      } : { }
    }
  >
    <Typography
      variant="caption"
      textAlign={"center"}
      sx={
        props.selected ? {
          color: (theme) => theme.palette.secondary.main,
        } : {}
      }
    >
      Day&nbsp;{props.index}
    </Typography>
    <Typography
      variant="caption"
      textAlign={"center"}
      fontWeight={"bold"}
      fontSize={"0.8rem"}
      sx={
        props.selected ? {
          color: (theme) => theme.palette.secondary.main,
        } : {}
      }
    >
      {props.date.toLocaleString('en-US', {month: 'short', day: '2-digit'}).replace(/\s/, '\u00A0')}
    </Typography>
    <Typography
      variant="caption"
      textAlign={"center"}
      fontWeight={900}
      sx={
        {
          color: props.selected ? (theme) => theme.palette.info.main :
            (props.userLogData === undefined || (props.userLogData.gambled === undefined && props.userLogData.mood === undefined)) ? (theme) => theme.palette.warning.main :
            (props.userLogData.gambled !== undefined && props.userLogData.mood !== undefined) ? (theme) => theme.palette.success.light :
            (theme) => theme.palette.info.light
        }
      }
    >
      { props.initializing ? <CircularProgress size={24} /> :
        // No log data
        props.userLogData === undefined || (props.userLogData.gambled === undefined && props.userLogData.mood === undefined) ? <AdjustIcon /> :
        // Gambling log filled => need to complete by filling the mood log
        (props.userLogData.gambled !== undefined && props.userLogData.mood === undefined ) ? <AddReactionIcon /> :
        // Mood log filled => need to complete by filling the gambling log
        (props.userLogData.gambled === undefined && props.userLogData.mood !== undefined ) ? <NoteAddIcon /> :
        // Everything is filled
        <CheckCircleIcon />}
    </Typography>    
  </Stack>)
}

interface CalendarStripProps {
  onSelectedDateChange?: ( date: Date ) => void,
  selectedDate?: Date,
  firstDate: Date,
  userLogData?: { [key: string]: UserLogEntry }
}


function CalendarStrip( props: CalendarStripProps ) {
  const today = new Date()
  const [selectedDate, setSelectedDate] = useState<Date>(props.selectedDate || today)
  const scrollRef = useRef<null | HTMLDivElement>(null)
  const parentRef = useRef<null | HTMLDivElement>(null)

  const scrollToVisibility = () => {
    const parentElement = parentRef.current;
    const childElement = scrollRef.current;

    if (!parentElement || !childElement) {
      return
    }
    const parentRect = parentElement.getBoundingClientRect();
    const childRect = childElement.getBoundingClientRect();

    const isChildVisible = (childRect.left >= parentRect.left) && (childRect.right <= parentRect.right);
    if (!isChildVisible) {
      parentElement.scrollLeft += (childRect.left - parentRect.left) - (parentRect.width / 2 - childRect.width / 2);
    }
  }

  useEffect(() => {
    scrollToVisibility()
  })

  let dates: Date[] = [];
  let currentDate = new Date()
  while (DateToString(currentDate) >= DateToString(props.firstDate)) {
    dates.unshift(currentDate);
    currentDate = new Date(currentDate.getTime() - 24 * 60 * 60 * 1000);
  }

  let futureDates: Date[] = [];
  for (let i = 1; i <= FUTURE_DAYS; i++) {
    futureDates.push(new Date(today.getTime() + i * 24 * 60 * 60 * 1000));
  }

  return (
  <Stack
    direction={"row"}
    spacing={2}
    paddingY={1}
    maxWidth={"100%"}
    sx={{overflowX: "auto", overflowY: "hidden", flexShrink: 0}}
    ref={parentRef}
  >
    {
      dates.map((date, index) =>
        <Box ref={areDatesEqual(date, selectedDate) ? scrollRef : null} key={index} sx={{cursor: 'pointer'}}>
          <CalendarStripTile
            date={date}
            index={index + 1}
            selected={areDatesEqual(date, selectedDate)}
            initializing={props.userLogData === undefined}
            userLogData={props.userLogData ? props.userLogData[DateToString(date)] : undefined}
            onClick={() => {setSelectedDate(date); props.onSelectedDateChange&&props.onSelectedDateChange(date)}}
          />
        </Box>)
    }
    {
      futureDates.map((date, index) => 
        <Box key={index} sx={{opacity: 0.5}}>
          <CalendarStripTile
            date={date}
            index={dates.length + index + 1}
            selected={false}
            initializing={props.userLogData === undefined}
          />
        </Box>
      )
    }
  </Stack>)
}

export default CalendarStrip;
