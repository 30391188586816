export const EXERCISE_NAME_DEMOGRAPHY = "Tailoring your experience"
export const EXERCISE_NAME_IMPRESSIONS = "Let's make this experience even better"
export const EXERCISE_NAME_HABITS = "Understanding your gambling"
export const EXERCISE_NAME_DEBTS = "Facing your debts"
export const EXERCISE_NAME_REASONS = "Reasons to gamble"

type ProtoMessage = {
    sender: string,
    text: string
}

type ProtoSessionTranscript = {
    userName: string,
    sessionName: string,
    status?: string,
    messages: ProtoMessage[]
}

type ProtoBlockStatus = "available" | "locked" | "completed" | "cooldown"
type ProtoBlockType = "session" | "exercise"

type ProtoBlock = {
    type: ProtoBlockType,
    name: string,
    status: ProtoBlockStatus,
    url?: string,
    cooldown?: number,
    pointsType1: number,
    pointsType2: number,
    cooldownOpeninigTS?: number
}

type ProtoUserDetails = {
    created?: string,
    updated?: string,
    lastMoodUpdate?: string,
    lastGLogUpdate?: string,
    subscribed?: boolean
}

type ProtoUser = {
    name: string,
    nickname?: string
    created: string,
    blocks: ProtoBlock[],
    currentBlock: number,
    pointsType1: number,
    pointsType2: number,
    details?: ProtoUserDetails,
}

interface UserLogEntry {
    gambled?: boolean,
    gamblingOutcome?: number,
    mood?: string,
    completedBlocks?: number[],
}

interface UserLogData {
    userName: string,
    logEntries?: {
        [key: string]: UserLogEntry
    }
}

interface AskMeMessage {
    message: string;
    sender: string;
    timestamp: number;
}

interface AskMeConversation {
    messages: AskMeMessage[];
    replied: boolean;
}

export type {
    ProtoBlock,
    ProtoBlockType,
    ProtoBlockStatus,
    ProtoSessionTranscript,
    ProtoUser,
    UserLogEntry,
    UserLogData,
    AskMeMessage,
    AskMeConversation
}
