import { Box, Stack, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import Pluralize from 'pluralize';

import {
  Forum as ForumIcon,
  EditNote as EditNoteIcon,
  LockOutlined as LockIcon,
  Check as CheckIcon,
  Flag as FlagIcon,
  HourglassTop as HourglassTopIcon,
} from '@mui/icons-material';
import { ProtoBlockStatus, ProtoBlockType } from "../prototypes";
import React from "react";

interface CourseItemTextProps {
  type: ProtoBlockType,
  name: string,
  duration: number;
  alignment?: 'left' | 'right',
  status: ProtoBlockStatus,
  cooldownTS?: number,
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

function timeUntilTimestamp( ts: number ) : string {
  const diff = ts - Math.floor(Date.now() / 1000);

  if (diff <= 0) {
    return `${ts}`
  }

  const days = Math.floor(diff / 86400);
  if (days > 0) {
    return `${days} ${Pluralize('day', days)}`
  }

  const hours = Math.floor((diff % 86400) / 3600);
  if (hours > 0) {
    return `${hours} ${Pluralize('hour', hours)}`
  }

  const minutes = Math.floor((diff % 3600) / 60);
  if (minutes > 0) {
    return `${minutes} ${Pluralize('minute', minutes)}`
  }

  const seconds = (diff % 60);
  if (seconds > 0) {
    return `${seconds} ${Pluralize('second', seconds)}`
  }

  return "";
}
 
function CourseItemText( props: CourseItemTextProps ) {
  const theme = useTheme();

  return (<Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      px={2}
      height={"100%"}
      maxWidth={"70%"}
      onClick={props.onClick}
      sx={{
        cursor: props.status === "available" ? 'pointer' : "auto",
      }}
    >
      {
        props.status === "available" &&
          <Box
          border={1}
          borderColor={"rgb(142, 145, 146)"}
          borderRadius={"8px"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          py={0.5}
          px={2}
          mb={1}
        >
          <Typography sx={{textTransform: "capitalize", color: "rgb(202, 196, 208)"}}>{props.type === "exercise" ? "task" : "chat"}</Typography>
        </Box>
      }
      <Typography textAlign={props.alignment} variant="h6" sx={{lineHeight: "22px", fontSize: "16px"}}>{props.name ? props.name : "Joining the journey"}</Typography>
      {/* Time label */}
      <Typography
        textAlign={props.alignment}
        variant="body1"
        sx={{color: theme.palette.primary.main, fontSize: "14px"}}
      >
        { props.status !== "cooldown" ?
          (props.name ? props.duration + " min" : "") :
          (props.cooldownTS ? `Opens in ${timeUntilTimestamp(props.cooldownTS)}` : "Will be available later")
        }
      </Typography>
    </Box>
    )
}

interface CourseItemProps {
  type: ProtoBlockType,
  name: string,
  duration: number,
  cooldownTS?: number,
  alignment?: 'left' | 'right',
  status: ProtoBlockStatus,
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

const CourseItem = React.forwardRef<HTMLDivElement, CourseItemProps> ( (props, ref) => {
  const theme = useTheme();

  return (
    <Stack
      alignSelf={props.alignment === 'left' ? 'end' : 'start'}
      direction={"row"}
      alignItems={"center"}
      width={'80%'}
      justifyContent={props.alignment === 'right' ? 'end' : 'start'}
      sx={{
        // backgroundColor: '#10101050',
      }}
    >
      {
        props.alignment === 'right' &&
          <CourseItemText
            alignment="right"
            onClick={props.onClick}
            type={props.type}
            name={props.name}
            duration={props.duration}
            status={props.status}
            cooldownTS={props.cooldownTS}
          />
      }
      <Box
        width={props.status === 'available' ? '96px' : '56px'}
        height={props.status === 'available' ? '96px' : '56px'}
        p={1}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        onClick={props.onClick}
        sx={{
          backgroundColor: props.name ? props.status === "locked" ? '#000000' : props.status === "completed" ? theme.palette.success.main : '#AE8DDD' : "#385400",
          cursor: props.status === "available" ? 'pointer' : "auto",
          borderRadius: props.status === 'available' ? '28px' : '16px',
        }}
        ref={ref}
      >
        {
          props.name === "" ? <FlagIcon sx={{color: "#B5FF1B", fontSize: '36px'}} /> :
          props.status === "locked" ? <LockIcon sx={{color: "rgb(177, 177, 177)", fontSize: '24px'}} /> :
          props.status === "completed" ? <CheckIcon sx={{color: theme.palette.success.contrastText}} /> :
          props.status === "cooldown" ? <HourglassTopIcon sx={{color: "black", fontSize: '36px'}} /> : 
          props.type === "session" ? <ForumIcon sx={{color: "black", fontSize: '36px'}} /> : 
          <EditNoteIcon sx={{color: "black", fontSize: '36px'}} />
        }
      </Box>
      {
        props.alignment !== 'right' &&
        <CourseItemText
          onClick={props.onClick}
          type={props.type}
          name={props.name}
          duration={props.duration}
          status={props.status}
          cooldownTS={props.cooldownTS}
        />
      }
    </Stack>
  )
})

export default CourseItem
