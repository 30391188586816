import {useContext, useEffect, useState} from 'react';

import {
  Box,
  Typography,
  Grid,
  Avatar,
  TextField,
  Button,
  Stack,
  Backdrop,
  CircularProgress,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';

// import { UserContext } from '../context/usercontext';
import AppbarMenu from '../components/appbarmenu';
import { BOTTOM_NAVBAR_HEIGHT, MAX_WIDTH, TOP_BACKGROUND_GRADIENT } from '../theme/gaimcontrolTheme'
import BottomNavBar from '../components/bottomnavbar';
import axios from 'axios';
import { UserContext } from '../context/usercontext';
import { AskMeConversation } from '../prototypes';
import {
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';

function AskMePage() {
  const { user } = useContext(UserContext);
  const [question, setQuestion] = useState<string>("");
  const [processing, setProcessing] = useState<boolean>(false);
  const [sendingEnabled, setSendingEnabled] = useState<boolean>(true);
  const [conversation, setConversation] = useState<AskMeConversation>();
  const theme = useTheme();

  useEffect(() => {
    setProcessing(true)
    axios.get<AskMeConversation>(process.env.REACT_APP_CHAT_SERVER_URL + "/askme", {
      headers: {
        "Authorization": process.env.REACT_APP_API_TOKEN,
      },
      params: {
          userName: user?.name
      }}
    ).then((response)=>{
      setConversation(response.data)
    }).catch((error) => { console.log(error) })
    .finally(() => { setProcessing(false) })
  }, [])

  const onSend = () => {
    setSendingEnabled(false)
    setProcessing(true)
    axios.post<AskMeConversation>(process.env.REACT_APP_CHAT_SERVER_URL + "/askme", { question: question }, {
      headers: {
          "Authorization": process.env.REACT_APP_API_TOKEN,
      },
      params: {
          userName: user?.name
      }
    }).then( response => {
      if (response.status === 200) {
        setConversation(response.data)
        alert("We have received your question. Jean-Paul will reply, and we will notify you about it.")
        setQuestion("")
      } else {
        alert("Something went wrong")
      }
    }).finally(() => {
      setSendingEnabled(true)
      setProcessing(false)
    })
  }

  return (
    <Box
      alignItems={'center'}
      display={'flex'}
      flexDirection={'column'}
      sx={{
        height: '100%',
        width: '100%',
        overflow: 'clip'
      }}
    >
      <AppbarMenu/>
      <Stack
        boxSizing={'border-box'}
        width={"100%"}
        maxWidth={MAX_WIDTH}
        flexGrow={1}
        spacing={2}
        px={2}
        sx={{
          background: TOP_BACKGROUND_GRADIENT,
          overflowY: 'auto'
        }}
      >
        <Grid container spacing={1} width={'100%'}>
          <Grid item xs={12} sm={3} display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <Avatar alt="Jean-Paul" src="/avatars/jean-paul.webp" sx={{ width: 128, height: 128 }}></Avatar>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Typography gutterBottom>Hi, I am Jean-Paul and I struggled with gambling for 17 years.
              You can ask me questions directly here; if I don't know the answers, I'll find someone
              who does and get back to you.</Typography>
          </Grid>
        </Grid>
        <Box>
          <Accordion disableGutters sx={{backgroundColor: theme.palette.secondary.dark}}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>How Did It Start For You?</AccordionSummary>
            <AccordionDetails><Typography variant='body2' gutterBottom>
              My story started with playing poker online then in person. I came across blackjack in a casino
              in person by accident after losing a poker tournament and I was on tilt. I won the first time
              which anchored it in my mind.</Typography><Typography variant='body2'>It really became an issue
              for me and I ended up going to play during the break at work. I later transitioned to playing
              online blackjack and other games, which further fueled my addiction. We all know the days where
              you wait until payday and deposit all or most of your salary within hours to be left wondering
              if this the live I want?</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion disableGutters sx={{backgroundColor: theme.palette.secondary.dark}}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>What Did You Try?</AccordionSummary>
            <AccordionDetails>
              <Typography variant='body2' gutterBottom>I tried sheer willpower or white knuckling at the
              beginning. It usually lasted only until I had money or for shorts period of times. I then starting
              using deposit limits but you can create an account online in minutes so that didn’t help. One
              of my close friends kept asking, why don’t you just stop? Which pushed me to self-exclude a few
              times but that was just longer breaks. I went on the national self-exclusion register but again
              that did not work.</Typography>
              <Typography variant='body2'>The same friend then suggested it might be a mental health issue.
              I tried GA but that did not work for me. I eventually tried a mental health professional but the
              first one was not qualified for gambling so it failed.</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion disableGutters sx={{backgroundColor: theme.palette.secondary.dark}}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>What Did Help You?</AccordionSummary>
            <AccordionDetails>
              <Typography variant='body2' gutterBottom>One thing gambling thought me is resilience. Remember that time
              when you lose on a site and try again on another?</Typography>
              <Typography variant='body2' gutterBottom>I asked myself: just because one method failed once, do I have to
              stop trying it? I tried a stronger self-exclusion with a blocking software, combined it with
              therapy, attended support meeting with qualified peer support and relied on the support of
              friends.</Typography>
              <Typography variant='body2' gutterBottom>What surprised me was that even the friends that I owed money to
              were willing to help me when I turned to them and told them what had happened to me and how
              I was trying to solve it.</Typography>
              <Typography variant='body2'>The regular practice of techniques I used in therapy helped
              me retrain my brain to react differently to triggers.</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion disableGutters sx={{backgroundColor: theme.palette.secondary.dark}}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>What is your life like now?</AccordionSummary>
            <AccordionDetails>
              <Typography variant='body2' gutterBottom>I am recovering from the financial issues but am doing so much better
              than trying to chase that big win that would erase them all.</Typography>
              <Typography variant='body2' gutterBottom>I am in a safe place now when I completely recovered and have full
              control of my finances, can deal with my emotions and do not need to be on the self-exclusion
              registers anymore as I am no longer tempted.</Typography>
              <Typography variant='body2' gutterBottom>I learned to build the life I want for myself and am helping more
              gamblers do the same.</Typography>
              <Typography variant='body2' gutterBottom>One final point: you are not just a gambler, you are a person who’s
              dreams and life objectives have been currently derailed by gambling.</Typography>
              <Typography variant='body2'>You can act now. You are not alone.</Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
        {conversation && conversation.messages && conversation.messages.map((item, index) => 
          <Box
            alignSelf={item.sender === "user" ? "end" : "start"}
            key={index}
            maxWidth={'80%'}
            sx={{
              borderRadius: '16px',
              background: item.sender === "user" ? theme.palette.primary.main : "#E2E2E2",
              paddingX: 2,
              paddingY: 1,
              marginY: 0,
            }}
          >
            {item.message.split('\n').map((text, textIndex) => <div key={textIndex}>{text}</div>)}
          </Box>
        )}
        <TextField
          multiline
          rows={4}
          variant='outlined'
          required
          placeholder="Send a question to me about my experience, my motivation, or anything else."
          value={question}
          onChange={(event) => setQuestion(event.target.value) }
          disabled = {conversation ? !(conversation.replied) : true}
        >
        </TextField>
        <Button variant='contained' disabled={(question === "") || !sendingEnabled} onClick={onSend}>Send</Button>
        <Box height={BOTTOM_NAVBAR_HEIGHT} />
      </Stack>
      <Box height={BOTTOM_NAVBAR_HEIGHT} />
      <BottomNavBar value={3}/>
      <Backdrop open={processing}>
          <CircularProgress color="inherit"/>
      </Backdrop>
    </Box>
  );
}

export default AskMePage
