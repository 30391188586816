import React, { useContext, useState } from 'react';
import {
  Button,
  TextField,
  Box,
  Stack,
  Typography,
  LinearProgress,
  Slide,
  Backdrop,
  CircularProgress
} from '@mui/material';
import { MAX_WIDTH, TOP_BACKGROUND_GRADIENT } from '../../theme/gaimcontrolTheme';

import axios from 'axios';
import { UserContext } from '../../context/usercontext';
import { getCookie } from 'typescript-cookie';
import { EXERCISE_NAME_IMPRESSIONS, ProtoUser } from '../../prototypes';
import FancyMessageBox from '../../components/fancymessagebox';
import { useNavigate } from 'react-router-dom';
import AnswerSelectorComponent from '../../components/answersliderselector';

interface Question {
  question: string;
  options?: string[]
}

const questions: Question[] = [
  {
    question: 'How did it go?',
    options: ["Awful", "So-so", "OK", "Awesome!"]},
  {
    question: 'Would you agree Anna helped you understand why you wanted to make changes around your gambling habits?',
    options: ["Completely disagree", "Somehow disagree", "Somehow agree", "Completely agree"],
  },
  {
    question: 'Would you agree you feel supported by Anna?',
    options: ["Completely disagree", "Somehow disagree", "Somehow agree", "Completely agree"],
  },
  {
    question: 'Do you have an understanding of how Anna can help you progress towards your goal?',
    options: ["No", "I can't say", "Yes"],
  },
  {
    question: 'Any additional comments about your experience? Anything you liked or disliked?'
  },
];

const ImpressionsQuestionnaire: React.FC = () => {
  const {user, setUser} = useContext(UserContext);
  const [index, setIndex] = useState(0);
  const [responses, setResponses] = useState<string[]>([]);
  const [answerOption, setAnswerOption] = useState<number>(-1);
  const [direction, setDirection] = useState<"left"|"right">("left");
  const [show, setShow] = useState<boolean>(true);
  const [finalDialog, setFinalDialog] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const navigate = useNavigate();

  // Handle the change in the answer
  const handleTextChange = (value: string) => {
    const newResponses = [...responses];
    newResponses[index] = value;
    setResponses(newResponses);
  };

  // Check, if the user can proceed to the next question (answered to the current one)
  const canProceed = () => {
    if (questions[index].options) {
      return true
    }
    return (responses[index] !== undefined) && (responses[index].length > 0);
  };

  const onAnswerOptionChange = ( value: number ) => {
    if (questions[index].options === undefined) {
      return
    }
    const options: string[] =  questions[index].options as string[];

    setAnswerOption(value)
    const newResponses = [...responses];
    newResponses[index] = options[value];
    setResponses(newResponses);
  }

  // Save the questionnaire replies
  const saveReplies = () => {
    setProcessing(true)
    var responsesToSave: string[] = []

    // setProcessing(true)
    questions.map((question, index) => {
      if (question.options) {
        responsesToSave.push(`${question.question}\n\n${responses[index]}`)
      } else {
        responsesToSave.push(`${question.question}\n\n${responses[index]}`)
      }
      return question;
    })

    console.debug("Saving the exercise...")
    axios.put(process.env.REACT_APP_CHAT_SERVER_URL + "/exercise", JSON.stringify(responsesToSave),
      {
        headers: {
          "Authorization": process.env.REACT_APP_API_TOKEN,
          "Content-Type": "application/json"
        },
        params: {
          userName: user?.name,
          exerciseName: encodeURIComponent(EXERCISE_NAME_IMPRESSIONS),
        }
      },
    ).then(
      response => {
        if (response.status === 200) {
          console.debug("Successful.")
          // setCelebration(true)
        }
        const code = getCookie("userName")
        console.debug("Reloading the user data...")
        return axios.get<ProtoUser>(process.env.REACT_APP_CHAT_SERVER_URL + `/courses?userName=` + code, {
          headers: {
            "Authorization": process.env.REACT_APP_API_TOKEN
          }      
        })
    }).then(response => {
      console.debug("Successful.")
      setUser(response.data);
      setFinalDialog(true);
      // setProcessing(false);
    }).catch(error => {
      console.log("Error saving the exercise.")
      // setErrorDialogOpen(true)
    }).finally( () => {
      setProcessing(false);
    })
  }

  return (
    <Box bgcolor={"#21263D"} width={"100%"} height={"100%"} display={"flex"} justifyContent={"center"}>
      <Stack width={"100%"} maxWidth={MAX_WIDTH} alignContent={"center"} spacing={2} display={"flex"} height={"100%"} alignItems={"center"}>
        <Typography variant='h3' pt={3} px={2}>Let's make this experience even better!</Typography>
        <LinearProgress variant='determinate' value={((index) / (questions.length - 1)) * 100} sx={{height: 4, width: "100%"}}/>

        <Box sx={{width: "100%", background: TOP_BACKGROUND_GRADIENT, overflowY: "auto"}} px={2}>
          <Slide direction={direction} in={show} timeout={250}><Box display={"flex"} alignItems={"center"} flexDirection={"column"}>
            <Typography variant='h5' my={5} mx={2}>{questions[index].question}</Typography>
            {
              (questions[index].options !== undefined) ? (
                <Box width={"90%"} px={3} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                  <AnswerSelectorComponent
                    options={questions[index].options as string[]}
                    onChange={onAnswerOptionChange}
                    value={answerOption}
                    timeout={1000}
                  />
                </Box>
              ) : (
                <TextField
                  autoFocus
                  label="Please type here"
                  multiline
                  variant='outlined'
                  rows={5}
                  placeholder='Your answer'
                  value={responses[index] || ""}
                  onChange={(event)  => handleTextChange(event.target.value)}
                  sx={{ width: "90%"}}
                  
                  />
              )
            }
          </Box></Slide>
        </Box>
        <Box flexGrow={1}></Box>
        <Box display={"flex"} flexDirection={"row"} width={"100%"} py={2}>
          <Button
            variant="contained"
            fullWidth
            onClick={() => {
              setDirection("left");
              setShow(false);
              setTimeout(() => {setIndex(prev => Math.max(prev - 1, 0)); setDirection("right"); setShow(true)}, 500);
            }}
            disabled={index === 0}
            sx={{margin: 2}}
          >
            Previous
          </Button>
          <Button
            variant="contained"
            fullWidth
            onClick={() => {
              if (index < questions.length - 1) {
                setDirection("right");
                setShow(false);                
                setTimeout(() => {setIndex(prev => prev + 1); setDirection("left"); setShow(true)}, 500);
              } else {
                // Handle the "Finish" action here
                saveReplies();
              }
            }}
            color={index < questions.length - 1 ? 'primary' : 'success'}
            disabled={!canProceed()}
            sx={{margin: 2}}
          >
            {index < questions.length - 1 ? 'Next' : 'Finish'}
          </Button>
        </Box>
      </Stack>
      <FancyMessageBox
        message="Thank you for your feedback, it will help us tremendously!"
        title='Keep going!'
        display={finalDialog}
        celebrate={true}
        heartsEarned={user?.blocks[user.currentBlock]?.pointsType1}
        gatherFeedback={true}
        onClose={() => {
          setFinalDialog(false);
          navigate("/")
        }}
      />
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={processing}
      >
        <CircularProgress/>
      </Backdrop>
    </Box>
  );
};

export default ImpressionsQuestionnaire;