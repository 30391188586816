import React, { useContext, useState } from 'react';
import { DndProvider, useDrag, useDrop, useDragLayer } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  TextField,
  Typography,
  Zoom
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  Cancel as CancelIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import { BACKGROUND_GRADIENT_TRANSPARENT_DO, MAX_WIDTH } from '../../theme/gaimcontrolTheme';
import AppbarMenu from '../../components/appbarmenu';
import FancyMessageBox from '../../components/fancymessagebox';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { UserContext } from '../../context/usercontext';
import axios from 'axios';
import { ProtoUser } from '../../prototypes';

const MIN_GROUP_HEIGHT = '150px'
const fullExerciseData = new Map<string, ExerciseData>();

fullExerciseData.set('finance',
  {
    exerciseName: "Financial consequences",
    heading: "Let's spot the dangers",
    subheading: "As you navigate the rocky slopes, you'll encounter treacherous " +
    "ledges of debt and financial strain. Identify the pitfalls and start charting " +
    "paths to regain stability.",
    cards: [
      {text: 'Large debts'},
      {text: 'Bankruptcy'},
      {text: 'Unpaid bills'},
      {text: 'Selling possessions'},
      {text: 'Using savings'},
      {text: 'Other'},    
    ],
  }
)
fullExerciseData.set('personal',
  {
    exerciseName: "Personal consequences",
    heading: "Let's spot the dangers",
    subheading: "Traversing the dense forest, you must find your way " +
      "through the tangled vines. They could be personal neglect, self-esteem, and many others. " +
      "Recognize obstacles and clear a path to self-improvement.",
    cards: [
      {text: 'Insomnia'},
      {text: 'Stress'},
      {text: 'Anxiety'},
      {text: 'Depression'},
      {text: 'Low self-esteem'},
      {text: 'Other'},    
    ],
    challengeImage: 'exercises/consequences/forest-svgrepo-com.svg',
    planImage: 'exercises/consequences/axe-svgrepo-com.svg'
  }
)
fullExerciseData.set('legal',
  {
    exerciseName: "Legal consequences",
    heading: "Let's spot the dangers",
    subheading: "Crossing the desert, you'll face the scorching sun of legal " +
      "issues and obligations. Identify the sources of heat and map out your " +
      "plan to find shade and resolve.",
    cards: [
      {text: 'Theft charges'},
      {text: 'Fraud charges'},
      {text: 'Money laundering'},
      {text: 'Contract disputes'},
      {text: 'Loan shark'},
      {text: 'Other'},    
    ],
    challengeImage: 'exercises/consequences/desert-svgrepo-com.svg',
    planImage: 'exercises/consequences/canteen-svgrepo-com.svg'
  }
)
fullExerciseData.set('professional',
  {
    exerciseName: "Professional consequences",
    heading: "Let's spot the dangers",
    subheading: "Climbing the steep cliffs of professional demands, be wary of loose rocks " +
      "and unstable footing representing professional challenges. Strengthen your handhold on " +
      "responsibility and achievement.",
    cards: [
      {text: 'Absenteeism'},
      {text: 'Declining performance'},
      {text: 'Disciplinary action'},
      {text: 'Job loss'},
      {text: 'Incomplete assignments'},
      {text: 'Other'},    
    ],
    planImage: 'exercises/consequences/ice-axe-svgrepo-com.svg',
  }
)
fullExerciseData.set('friends',
  {
    exerciseName: "Consequences on friendship",
    heading: "Let's spot the dangers",
    subheading: "Journeying through the swamplands, beware of the quicksand " +
      "of strained friendships. Identify the unstable ground and find solid " +
      "footing to rebuild trust and support.",
    cards: [
      {text: 'Lost trust'},
      {text: 'Borrowing money'},
      {text: 'Avoidance'},
      {text: 'Arguments'},
      {text: 'Broken promises'},
      {text: 'Other'},    
    ],
    challengeImage: 'exercises/consequences/wetland-svgrepo-com.svg',
    planImage: 'exercises/consequences/rubber-boot-svgrepo-com.svg',
  }
)
fullExerciseData.set('family',
  {
    exerciseName: "Consequences for your family",
    heading: "Let's spot the dangers",
    subheading: "Throughout the stormy seas, choppy waves " +
      "represent family conflicts and strained relationships. Chart your " +
      "course to calmer waters and mend familial bonds.",
    cards: [
      {text: 'Marital problems'},
      {text: 'Neglecting children'},
      {text: 'Domestic disputes'},
      {text: 'Financial strain'},
      {text: 'Keeping secrets'},
      {text: 'Other'},    
    ],
    challengeImage: 'exercises/consequences/glacier-svgrepo-com.svg',
    planImage: 'exercises/consequences/map-svgrepo-com.svg',
  }
)
fullExerciseData.set('health',
  {
    exerciseName: "Consequences for your health",
    heading: "Let's spot the dangers",
    subheading: "Exploring the cavernous depths, you encounter the darkness " +
      "of medical issues and health neglect. Light your way through the labyrinth " +
      "to better health and proactive care.",
    cards: [
      {text: 'Stress-related illnesses'},
      {text: 'Heart problems'},
      {text: 'Substance abuse'},
      {text: 'Lack of sleep'},
      {text: 'Weight changes'},
      {text: 'Other'},    
    ],
    challengeImage: 'exercises/consequences/cave-nature-svgrepo-com.svg',
    planImage: 'exercises/consequences/helmet-svgrepo-com.svg',
  }
)
fullExerciseData.set('emotional',
  {
    exerciseName: "Emotional consequences",
    heading: "Let's spot the dangers",
    subheading: "As you navigate the foggy moors of emotional turmoil, " +
      "beware of the unseen ditches of psychological distress. Clear the fog " +
      "by recognizing and addressing emotional challenges.",
    cards: [
      {text: 'Guilt'},
      {text: 'Shame'},
      {text: 'Hopelessness'},
      {text: 'Despair'},
      {text: 'Suicidal thoughts'},
      {text: 'Other'},    
    ],
    challengeImage: 'exercises/consequences/mist-svgrepo-com.svg',
    planImage: 'exercises/consequences/lantern-svgrepo-com.svg',
  }
)

const CustomDragLayer: React.FC = () => {
    const {
      isDragging,
      item,
      initialOffset,
      currentOffset,
    } = useDragLayer((monitor) => ({
      item: monitor.getItem(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
  }));

  if (!isDragging) {
    return null;
  }

  const layerStyles: React.CSSProperties = {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 1000,
    left: 0,
    top: 0,
    opacity: 0.8,
  };

  const getItemStyles = (initialOffset: any, currentOffset: any) => {
    if (!initialOffset || !currentOffset) {
      return {
        display: 'none',
      };
    }

    const { x, y } = currentOffset;
    const transform = `translate(${x}px, ${y}px)`;
    return {
      transform,
      WebkitTransform: transform,
    };
  };

  return (
    <div style={layerStyles}>
      <div style={getItemStyles(initialOffset, currentOffset)}>
        <Card card={item} canDrag={false}></Card>
      </div>
    </div>
  );
};

type CardType = {
  text: string;
  reason?: string;
  action?: string;
};

interface ExerciseData {
  exerciseName: string;
  heading: string;
  subheading: string;
  cards: CardType[];
  challengeImage?: string;
  planImage?: string;
}

const Card: React.FC<{ card: CardType; canDrag: boolean }> = ({ card, canDrag }) => {
  const theme = useTheme();

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'CARD',
    item: card,
    canDrag: canDrag,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <Stack
      ref={drag}
      direction={'row'}
      alignItems={'center'}
      sx={{
        padding: 1,
        margin: 0.5,
        borderRadius: '5px',
        backgroundColor: theme.palette.primary.main,
      }}
    >
      <Typography variant='body2' flexGrow={1} sx={{color: theme.palette.primary.contrastText}}>{card.text}</Typography>
      <IconButton disabled sx={{visibility: 'hidden', color: theme.palette.primary.contrastText}} size='small'><CancelIcon/></IconButton>
    </Stack>
  );
};

const DropZone: React.FC<{
    label: string;
    image: string;
    cards?: CardType[];
    onDrop: (item: CardType) => void;
    onDelete: (card : CardType) => void
  }> = ({ label, image, cards, onDrop, onDelete }) => {
  const theme = useTheme();
  const [, drop] = useDrop(() => ({
    accept: 'CARD',
    drop: (item: CardType) => onDrop(item)
  }));

  return (
    <Stack
      ref={drop}
      flexGrow={1}
      spacing={2}
      p={2}
      alignItems={'center'}
      width={'100%'}
      minHeight={MIN_GROUP_HEIGHT}
      sx={{
        border: `1px dotted grey`,
        boxSizing: 'border-box',
        borderRadius: '20px',
        background: `${BACKGROUND_GRADIENT_TRANSPARENT_DO}, url('/${image}')`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <Typography>{label}</Typography>
      {
        cards && cards.length > 0 && (
          <Grid container>
              { cards.map((card, index) => (
                <Grid item sm={4} xs={12} key={index}>
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    sx={{
                      padding: 1,
                      margin: 0.5,
                      borderRadius: '5px',
                      backgroundColor: theme.palette.primary.main,
                    }}
                  >
                    <Typography variant='body2' flexGrow={1} sx={{color: theme.palette.primary.contrastText}}>{card.text}</Typography>
                    <IconButton sx={{color: theme.palette.primary.contrastText}} size='small' onClick={()=>onDelete(card)}><CancelIcon/></IconButton>
                  </Stack>
                </Grid>
              ))}
          </Grid>
        )
      }
    </Stack>
  );
};

interface GamblingConsequencesProps {
  cardPool: CardType[];
  selectedCards: CardType[];
  explanation?: string;
  onDrop: (item: CardType) => void;
  onDelete: (card : CardType) => void;
  challengeImage?: string
}

const GamblingConsequences: React.FC<GamblingConsequencesProps> = ( props: GamblingConsequencesProps ) => {
  return (
    <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
      <CustomDragLayer />
      <Stack
        maxWidth={MAX_WIDTH} 
        width={"100%"}
        flexGrow={1}
        alignSelf={'center'}
      >
        <Stack my={2} alignItems={"center"} width={"100%"} alignSelf={'center'} spacing={4}>
          <Typography textAlign={'center'}>{props.explanation}</Typography>
          {/* Selected cards */}
          <DropZone
              label="My list of dangers"
              onDrop={props.onDrop}
              onDelete={props.onDelete}
              cards={props.selectedCards}
              image={props.challengeImage ? props.challengeImage : "exercises/consequences/mountain-svgrepo-com.svg"}
          />

          {/* Pool of cards */}
          <Grid container spacing={0}
            minHeight={MIN_GROUP_HEIGHT}
            sx={{
              border: `1px dotted grey`,
              boxSizing: 'border-box',
              borderRadius: '20px',
              background: `${BACKGROUND_GRADIENT_TRANSPARENT_DO}, url('exercises/consequences/camping-svgrepo-com.svg')`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              padding: 2,
            }}
          >
            {props.cardPool.map((card) => (
              <Grid item sm={4} xs={12} key={card.text}>
                <Card card={card} key={card.text} canDrag={true}/>
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Stack>
    </DndProvider>
  );
};

interface ConsequenceActionProps {
  card: CardType;
  onReasonChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onActionChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>; 
}

function ConsequenceAction( props: ConsequenceActionProps ) {
  const theme = useTheme();

  return (
    <Zoom in={true}>
      <Stack
        justifyContent={'start'}
        spacing={2}
        px={2}
        py={2}
        flexGrow={1}
      >
        <Box
          sx={{
            border: '1px solid black',
            borderRadius: '5px',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            justifyContent: 'center'
          }}
        >
          <Typography
            variant='h5'
            margin={2}
            sx={{color: theme.palette.primary.contrastText}}
            textAlign={'center'}
          >
              {props.card.text}
          </Typography>
        </Box>
        <TextField
          variant='outlined'
          multiline
          rows={2}
          label='Why this is a big challenge for you?'
          value={props.card.reason}
          onChange={props.onReasonChange}
        />
        <TextField
          variant='outlined'
          multiline
          rows={5}
          label='What are you planning to do about it?'
          value={props.card.action}
          onChange={props.onActionChange}

        />
      </Stack>
    </Zoom>
  )
}

const GamblingConsequencesExercise: React.FC = () => {
  const [searchParams] = useSearchParams();
  const area = searchParams.get("area");
  const exercideData = (area === null || fullExerciseData.get(area) === null ? fullExerciseData.get("personal") : fullExerciseData.get(area));
  const [cardPool, setCardPool] = useState<CardType[]>(exercideData?.cards as CardType[]);
  const [selectedCards, setSelectedCards] = useState<CardType[]>([]);
  const [taskIndex, setTaskIndex] = useState<number>(-1)
  const [processing, setProcessing] = useState<boolean>(false)
  const [finalDialog, setFinalDialog] = useState<boolean>(false)
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const theme = useTheme();

  const onDrop = (card: CardType ) => {
    if (card.text !== "Other") {
      setSelectedCards((prev) => [...prev, card]);
      setCardPool((prev) => prev.filter((c) => c.text !== card.text));
    } else {
      const name = prompt("What is the name of the danger?")
      const nextID = cardPool.length + selectedCards.length
      if (name && name !== 'Other') {
        setSelectedCards((prev) => [...prev, {id: nextID, text: name}]);
      }
    }
  };

  const onDelete = ( card: CardType ) => {
    setSelectedCards((prev) => prev.filter((c) => c.text !== card.text));
    setCardPool((prev) => [card, ...prev]);
  }

  const onDone = () => {
    setProcessing(true)
    axios.put(process.env.REACT_APP_CHAT_SERVER_URL + "/exercise",
      JSON.stringify(selectedCards as CardType[]), {
        headers: {
          "Authorization": process.env.REACT_APP_API_TOKEN,
          "Content-Type": "application/json"
        },
        params: {
          userName: user?.name,
          exerciseName: encodeURIComponent(exercideData?.exerciseName as string),
        }
    }).then(
      response => {
        return axios.get<ProtoUser>(process.env.REACT_APP_CHAT_SERVER_URL + `/courses?userName=` + user?.name, {
          headers: {
            "Authorization": process.env.REACT_APP_API_TOKEN
          }      
        })
    }).then(
      response => {
        setUser(response.data)
        setFinalDialog(true)
    }).catch(error => {
      console.log(error)
    }).finally(() => {
      setProcessing(false)
    })
  }

  const disableContinue = () => {
    if (taskIndex === -1) {
      return selectedCards.length === 0
    }
    if (taskIndex >= 0) {
      return (selectedCards[taskIndex].action === "" || selectedCards[taskIndex].action === undefined ||
        selectedCards[taskIndex].reason === "" || selectedCards[taskIndex].reason === undefined)
    }
  }

  return (
    <Stack
      alignSelf={"center"}
      alignItems={"center"}
      justifyContent={"center"}
      height={"100%"}
      maxWidth={MAX_WIDTH}
      width={"100%"}
      padding={2}
      sx={{
        boxSizing: 'border-box',
        overflowY: 'auto',
        backgroundImage: "url('/background_spots.png'), linear-gradient(180deg, #21263D 0%, #2D3453 100%)",
        backgroundRepeat: 'repeat-y',
        backgroundSize: "100% auto",
        backgroundAttachment: 'local',
      }}
    >
      <AppbarMenu />
      <Typography variant='h3' my={3}>{exercideData?.heading}</Typography>
      {
        // Selecting consequences
        taskIndex === -1 ?
        <GamblingConsequences
          explanation={exercideData?.subheading}
          cardPool={cardPool}
          selectedCards={selectedCards}
          onDelete={onDelete}
          onDrop={onDrop}
          challengeImage={exercideData?.challengeImage ? exercideData.challengeImage : "exercises/consequences/mountain-svgrepo-com.svg"}
        />
        // Putting details for each selected consequence
        : taskIndex < selectedCards.length ?
        <Stack
          flexGrow={1}
          width={'100%'}
          spacing={2}
          sx={{
            boxSizing: 'auto'
          }}
        >
          <LinearProgress
            variant='determinate'
            value={((taskIndex + 1) / selectedCards.length) * 100}/>
          <ConsequenceAction
            key={taskIndex}
            card={selectedCards[taskIndex]}
            onReasonChange={(e) => {
              const updatedCards = [...selectedCards];
              updatedCards[taskIndex].reason = e.target.value;
              setSelectedCards(updatedCards);
            }}
            onActionChange={(e) => {
              const updatedCards = [...selectedCards];
              updatedCards[taskIndex].action = e.target.value;
              setSelectedCards(updatedCards);
            }}
          />
        </Stack>
        // Finalizing the exercise
        :
        <Stack flexGrow={1}>
          <Typography textAlign={'center'}>Congratulations! You have listed your challenges, and put planned actions for them.</Typography>
          <Stack
            minHeight={MIN_GROUP_HEIGHT}
            width={'100%'}
            spacing={2}
            flexGrow={1}
            my={2}
            sx={{
              border: `1px dotted grey`,
              boxSizing: 'border-box',
              borderRadius: '20px',
              background: `${BACKGROUND_GRADIENT_TRANSPARENT_DO}, ` +
                `url('${exercideData?.planImage ? exercideData.planImage : "exercises/consequences/climbing-svgrepo-com.svg"}')`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              padding: 2,
            }}
          >
            {
              selectedCards.map(card => 
                <Accordion
                  key={card.text}
                  sx={{
                    border: '1px solid black',
                    borderRadius: '5px',
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    justifyContent: 'center'
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.primary.contrastText}} />}
                  >
                    {card.text}
                  </AccordionSummary>
                  <AccordionDetails sx={{paddingTop: 0}}>
                    <Typography color={theme.palette.primary.contrastText} variant='body1'>{card.reason}</Typography>
                    <Typography color={theme.palette.primary.contrastText} variant='body2'>{card.action}</Typography>
                  </AccordionDetails>
                </Accordion>
              )
            } 
          </Stack>
        </Stack>
      }
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}
        open={processing}
      >
        <CircularProgress/>
      </Backdrop>
      <FancyMessageBox
        message={"Thinking about what items you carry, and what makes you carry those items, is one step towards changing your load"}
        title='Well done.'
        display={finalDialog}
        heartsEarned={user?.blocks[user.currentBlock - 1]?.pointsType1 || 0}
        onClose={() => {
          setFinalDialog(false);
          navigate("/")
        }}
      />
      <Grid container spacing={2}>
        {
          taskIndex > -1 && 
          <Grid item xs={12} sm={6}>
            <Button variant='outlined' fullWidth disabled={selectedCards.length === 0} onClick={() => setTaskIndex(taskIndex - 1)}>
              Back
            </Button>
          </Grid>
        }
        {
          taskIndex < 0 && 
          <Grid item xs={12} sm={6}>
            <Button variant='outlined' color='warning' fullWidth disabled={selectedCards.length > 0}>
              Skip exercise
            </Button>
          </Grid>
        }
        {
          (taskIndex < selectedCards.length || selectedCards.length === 0) &&
          <Grid item xs={12} sm={6} key={taskIndex}>
          <Button variant='contained' color='success' fullWidth disabled={disableContinue()} onClick={() => setTaskIndex(taskIndex + 1)}>
            Continue
          </Button>
          </Grid>
        }
        {
          (taskIndex === selectedCards.length && selectedCards.length > 0) &&
          <Grid item xs={12} sm={6}>
          <Button variant='contained' color='success' fullWidth disabled={selectedCards.length === 0} onClick={onDone}>
            Done
          </Button>
          </Grid>
        }
      </Grid>
    </Stack>
  );
}

export default GamblingConsequencesExercise;
