import {
  Favorite as FavoriteIcon
} from "@mui/icons-material";
import { Box } from "@mui/material";

interface GradientHeartIconProps {
  size?: number | string;
}

function GradientHeartIcon(  props: GradientHeartIconProps ) {
  return (<Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
    <svg width={0} height={0}>
      <linearGradient id="gradientHeartIconColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#FF5449" />
        <stop offset={1} stopColor="#99322C" />
      </linearGradient>
    </svg>
    <FavoriteIcon sx={{fill: "url(#gradientHeartIconColors)", fontSize: props.size}}></FavoriteIcon>
  </Box>)
}

export default GradientHeartIcon;
