import { Box, IconButton, Stack, Typography } from "@mui/material";
import {
  AddReaction as AddReactionIcon,
  Check as CheckIcon,
  SentimentNeutralOutlined as SentimentNeutralIcon,
  SentimentNeutralTwoTone as SentimentNeutralTwoToneIcon,
  SentimentSatisfiedOutlined as SentimentSatisfiedIcon,
  SentimentSatisfiedTwoTone as SentimentSatisfiedTwoToneIcon,
  SentimentVerySatisfiedOutlined as SentimentVerySatisfiedIcon,
  SentimentVerySatisfiedTwoTone as SentimentVerySatisfiedTwoToneIcon,
  SentimentDissatisfiedOutlined as SentimentDissatisfiedIcon,
  SentimentDissatisfiedTwoTone as SentimentDissatisfiedTwoToneIcon,
  SentimentVeryDissatisfiedOutlined as SentimentVeryDissatisfiedIcon,
  SentimentVeryDissatisfiedTwoTone as SentimentVeryDissatisfiedTwoToneIcon,
} from '@mui/icons-material';
import { UserLogEntry } from "../prototypes";

const CHECK_ICON_SIZE = 24

interface DailyMoodRecordTaskItemProps {
  record?: UserLogEntry | null;
  today?: boolean;
  onClick: ( mood: string ) => void;
  num?: number;
}

function DailyMoodRecordTaskItem( props: DailyMoodRecordTaskItemProps ) {
  return (<Stack spacing={3} py={0.5} direction={"row"}>
    {/* Clickable - action icon and text */}
    <Stack
      direction={"row"}
      spacing={2}
      flexGrow={1}
      px={"10px"}
      alignItems={"center"}
      sx={{
        backgroundColor: "#369eff",
        borderRadius: "28px"
      }}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        ml={1}
        sx={{
          backgroundColor: '#EAF2F0',
          width: `40px`,
          height: `40px`,
          borderRadius: "50%"
        }}
      >
        {
          props.record === undefined || props.record?.mood  === undefined ?
          <AddReactionIcon sx={{fontSize: "24px", color: "#EAB308"}} /> :
          <CheckIcon sx={{fontSize: "24px", color: "#44B9AC"}} />
        }
      </Box>
      <Box flexGrow={1} padding={1}>
        <Typography gutterBottom variant='body1' sx={{color: "#10243e", fontSize: "14px", lineHeight: "20px"}}>
          {props.today ? "How are you feeling today?" : "How did you feel back then?"}
        </Typography>
        <Stack direction={"row"}>
          <Stack direction="row" sx={{borderRadius: '8px'}} spacing={0.5}>
            <IconButton sx={{minWidth: '0px', fontSize: '30px', p: '0px'}} onClick={()=>props.onClick('😞')}>
              {
                props.record?.mood !== '😞' ? 
                <SentimentVeryDissatisfiedIcon sx={{m: '5px', color: '#10243e'}}/> :
                <SentimentVeryDissatisfiedTwoToneIcon color="info" sx={{fontSize: '30px', color: '#fff'}}/>
              }              
            </IconButton>
            <IconButton sx={{minWidth: '0px', fontSize: '30px', p: '0px'}} onClick={()=>props.onClick('🙁')}>
              {
                props.record?.mood !== '🙁' ? 
                <SentimentDissatisfiedIcon color="info" sx={{m: '5px', color: '#10243e'}}/> :
                <SentimentDissatisfiedTwoToneIcon color="info" sx={{fontSize: '30px', color: '#fff'}}/>
              }
            </IconButton>
            <IconButton sx={{minWidth: '0px', fontSize: '30px', p: '0px'}} onClick={()=>props.onClick('😐')}>
              {
                props.record?.mood !== '😐' ? 
                <SentimentNeutralIcon color="info" sx={{m: '5px', color: '#10243e'}}/> :
                <SentimentNeutralTwoToneIcon color="info" sx={{fontSize: '30px', color: '#fff'}}/>
              }
            </IconButton>
            <IconButton sx={{minWidth: '0px', fontSize: '30px', p: '0px'}} onClick={()=>props.onClick('🙂')}>
              {
                props.record?.mood !== '🙂' ?
                <SentimentSatisfiedIcon color="info" sx={{m: '5px', color: '#10243e'}}/> :
                <SentimentSatisfiedTwoToneIcon color="info" sx={{fontSize: '30px', color: '#fff'}}/>
              }
            </IconButton>
            <IconButton sx={{minWidth: '0px', fontSize: '30px', p: '0px'}} onClick={()=>props.onClick('😁')}>
              {
                props.record?.mood !== '😁' ?
                <SentimentVerySatisfiedIcon color="info" sx={{m: '5px', color: '#10243e'}}/> :
                <SentimentVerySatisfiedTwoToneIcon color="info" sx={{fontSize: '30px', color: '#fff'}}/>
              }
            </IconButton>
          </Stack>
          <Box flexGrow={1}/>
        </Stack>
      </Box>
    </Stack>
  </Stack>)
}

export default DailyMoodRecordTaskItem
