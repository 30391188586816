import { Box, Grid, Stack } from "@mui/material"
import SelectorBar from "./selectorbar"
import { useEffect, useState } from "react"
import Chart from "react-apexcharts";
import { UserLogEntry } from "../prototypes";
import DateToString, { DAYS_IN_CALENDAR } from "../helpers/dateformatting";

const MoodToNumberMapping = new Map<string, number>();
MoodToNumberMapping.set("😞", -2)
MoodToNumberMapping.set("🙁", -1)
MoodToNumberMapping.set("😐", 0)
MoodToNumberMapping.set("🙂", 1)
MoodToNumberMapping.set("😁", 2)

const ApexDataLabels = 
{
  mood: {
    enabled: true,
    offsetY: 3,
    background: {
      enabled: false,
    },
    style: {
      fontSize: "20px",
    },
    formatter: function (val: any, opts?: any) {
      return (val === -2 ? "😞" : val === -1 ? "🙁" : val === 0 ? "😐" : val === 1 ? "🙂" : val === 2 ? "😁" : "")
    }
  },
  gambling: {
    enabled: false
  }
}

const ApexMarkers = 
{
  mood: {
  },
  gambling: {
    size: 10,
    colors: ["#6E4F9A"],
    strokeWidth: 4,
  }
}

const ApexYAxis = {
  gambling: {
    forceNiceScale: true,
    decimalsInFloat: 0,
    show: true,
    max: (max: number)=> { return max > 100 ? Math.ceil(max / 100) * 100 : 100 },
    min: (min: number)=> { return min < -100 ? Math.floor(min / 100) * 100  : -100 },
    labels: {
      style: {
        colors: 'white',
        fontSize: "12px",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 900
      }
    }
  },
  mood: {
    show: false,
    stepSize: 1,
    max: 2,
    min: -2,
    labels: {
      style: {
        colors: 'white',
        fontSize: "12px",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 900
      },
      formatter: function (val: any) {
        if (val === null)
          return "";
        return (val === -2 ? "😞" : val === -1 ? "🙁" : val === 0 ? "😐" : val === 1 ? "🙂" : val === 2 ? "😁" : val.toString())
      }
    }
  }
}

var initialChartOptions: ApexCharts.ApexOptions = {
  tooltip: {
    enabled: false,
  },
  chart: {
    id: "gambling-log-graph",
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false
    },
  },
  legend: {
    show: false,
  },
  dataLabels: {
  },
  stroke: {
    curve: 'straight'
  },
  xaxis: {
    categories: [],
    labels: {
      style: {
        colors: 'white',
        fontSize: "12px",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 900
      }
    }
  },
  markers: {
  },
  grid: {
    show: true,
    borderColor: "white",
    strokeDashArray: 10,
    
  },
  fill: {
    gradient: {
      shade: "light",
      type: "vertical",
      shadeIntensity: 0,
      opacityFrom: 1,
      opacityTo: 0,
      stops: [0, 25]
    }
  },
  annotations: {
    yaxis: [{
      y: 0,
      borderColor: 'white',
      fillColor: 'white',
      opacity: 1,
      strokeDashArray: 0,
    }]
  },
}

interface GamblingLogGraphProps {
  userLogData?: {[key: string]: UserLogEntry},
}

function GamblingLogGraph( props: GamblingLogGraphProps ) {
  const [aspect, setAspect] = useState<'mood' | 'gambling'>('gambling')
  const [period, setPeriod] = useState<'week' | 'month'>('month')

  const [chartOptions, setChartOptions] = useState<ApexCharts.ApexOptions>(initialChartOptions)
  const [chartData, setChartData] = useState<(number | null)[]>([])

  const [key, setKey] = useState<number>(0)
  const [gamblingData, setGamblingData] = useState<(number | null)[] | undefined>()
  const [moodData, setMoodData] = useState<(number | null)[] | undefined>()

  useEffect(() => {
    if (!props.userLogData) {
      return;
    }

    const today = new Date()
    const dates = Array.from({ length: DAYS_IN_CALENDAR }, (_, i) => {
      return new Date(today.getTime() - (DAYS_IN_CALENDAR - 1 - i) * 24 * 60 * 60 * 1000);
    });
    const newGamblingData: (number | null)[] = []
    const newMoodData: (number | null)[] = []

    if (props.userLogData === undefined) {
      setGamblingData(undefined);
      setMoodData(undefined);
      setPeriod('week')
      return
    }
    for (let i = 0; i < dates.length; i++) {
      let key = DateToString(dates[i]);
      const logData = props.userLogData[key]
      if (logData === undefined) {
        newGamblingData.push(null);
        newMoodData.push(null);
        continue
      }
      if (logData.gambled === undefined) {
        newGamblingData.push(null);
      } else if (logData.gambled === false) {
        newGamblingData.push(0);
      } else if (logData.gamblingOutcome === undefined) {
        newGamblingData.push(null);
      } else {
        newGamblingData.push(logData.gamblingOutcome)
      }
      const moodValue = props.userLogData[key].mood
      const numericMood = (moodValue !== undefined ? MoodToNumberMapping.get(moodValue) : undefined)
      newMoodData.push(numericMood === undefined ? null : numericMood);
    }
    setGamblingData(newGamblingData)
    setMoodData(newMoodData)
    setPeriod('week')
  }, [props.userLogData])


  useEffect(() => {
    const length = (period === 'week' ? 7 : 30)
    const today = new Date()
    const dates = Array.from({ length: length }, (_, i) => {
      return new Date(today.getTime() - (length - 1 - i) * 24 * 60 * 60 * 1000);
    });

    const newChartOptions = chartOptions
    const chartData = (aspect === 'gambling' ? gamblingData?.slice(-length) : moodData?.slice(-length))
    const categories: string[] = []

    for (let i = 0; i < length; i++) {
      const date = dates[i]
      if (newChartOptions && newChartOptions.xaxis) {
        categories.push(period === 'week' ?
          date.toLocaleDateString("en-US", { weekday: 'short' }) : 
          date.toLocaleDateString("en-US", { day: '2-digit' })
        )
      }
    }

    newChartOptions.xaxis = {
      ...newChartOptions.xaxis,
      categories: categories
    }
    newChartOptions.dataLabels = ApexDataLabels[aspect]
    newChartOptions.yaxis = ApexYAxis[aspect]
    newChartOptions.markers = ApexMarkers[aspect]
    setChartOptions(newChartOptions)
    chartData && setChartData(chartData)
    setKey(key => key + 1)
  }, [period, aspect])

  return (
    <Box>
      {/* Graph mode selector*/}
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Stack direction="row" alignItems={"center"}>
            <Box flexGrow={1}/>
            <SelectorBar
                options={["Gambling", "Mood"]}
                selected={aspect === 'gambling' ? 0 : 1}
                onChange={(value) => (value === 0 ? setAspect('gambling') : setAspect('mood'))}
            />
            <Box flexGrow={1}/>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Stack direction="row" alignItems={"center"}>
            <Box flexGrow={1}/>
            <SelectorBar
                options={["Last week", "Last month"]}
                selected={period === 'week' ? 0 : 1}
                onChange={(value) => {value === 0 ? setPeriod('week') : setPeriod('month')}}
            />
            <Box flexGrow={1}/>
          </Stack>
        </Grid>

      </Grid>

      {/* Graph */}
      <Box px={2} maxWidth={'100%'}>
        <Chart
          key={key}
          options={chartOptions}
          series={[
            {
              color: "#D7BAFF",
              name: "Data",
              data: chartData,
            }
          ]}
          type="area"
        />  
      </Box>
    </Box>
  )
}

export default GamblingLogGraph;
