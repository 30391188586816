import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Zoom
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import { useNavigate } from "react-router-dom";

const options: string[] = [
  "🃏 Poker",
  "♦️ Blackjack",
  "🎡 Roulette",
  "🎰 Slot Machines",
  "🏇 Horse betting",
  "⛹️ Sports betting",
  "🎟 Lottery",
  "🎯️ Bingo",
  "💳 Scratch cards",
  "🎰 Online slots",
  "🃏 Online poker",
  "🎡 Online roulette",
  "📈 Day trading",
  "❓ Other",
];

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
  ) {
    return <Zoom ref={ref} {...props} />;
  });

interface GamblingHabitMessageBoxProps {
  open: boolean;
  title?: string;
  disableNext?: boolean;
  disableDone?: boolean;
  game?: string;
  explanation?: string;
  duration?: string;
  reason?: string;
  onGameChange?: (game: string) => void;
  onExplanationChange?: (explanation: string) => void;
  onDurationChange?: (duration: string) => void;
  onReasonChange?: (reason: string) => void;
  onNext?: () => void;
  onDone?: () => void;
}

function GamblingHabitMessageBox( props: GamblingHabitMessageBoxProps ) {
  const navigate = useNavigate();

  return (
  <Dialog
    TransitionComponent={Transition}
    open={props.open}
    PaperProps={{
      sx: { borderRadius: "28px", padding: "5px"},
    }}
  >
    <DialogTitle>
      {props.title}
    </DialogTitle>
    <DialogContent>
      <Stack spacing={3}>
        <FormControl fullWidth sx={{m: 2}}>
          <InputLabel id="demo-simple-select-label">Game</InputLabel>
          <Select
            label="Game"
            value={props.game}
            id="demo-simple-select"
            labelId="demo-simple-select-label"
            onChange={(event) => props.onGameChange && props.onGameChange(event.target.value as string)}
          >
            {
              options.map((option, index) => (
                <MenuItem key={index} value={option}>{option}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
        <TextField
          sx={{display: props.game === "❓ Other" ? "flex" : 'none'}}
          label="What?"
          value={props.explanation}
          rows={2}
          onChange={(e) => props.onExplanationChange && props.onExplanationChange(e.target.value)}
        />
        <TextField
          type="number"
          label="Years"
          value={props.duration}
          onChange={(e) => props.onDurationChange && props.onDurationChange(e.target.value)}
        />
        <Typography variant="body2"></Typography>
        <TextField
          multiline
          value={props.reason}
          label={"What do you enjoy about it?"}
          rows={2}
          onChange={(e) => props.onReasonChange && props.onReasonChange(e.target.value)}
        />
      </Stack>
    </DialogContent>
    <DialogActions>
      <Button color={'warning'} onClick={() => navigate('/')}>Cancel</Button>
      <Button disabled={props.disableDone} onClick={props.onDone}>Done</Button>
      <Button disabled={props.disableNext} variant="contained" onClick={props.onNext}>Next</Button>
    </DialogActions>
   </Dialog>)
}

export default GamblingHabitMessageBox;
