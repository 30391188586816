import {useContext, useEffect, useRef, useState} from 'react';
import ReactGA from "react-ga4";

import {
  Backdrop,
  Box,
  Button,
  Typography
} from '@mui/material';
import { keyframes } from '@mui/system';

import { UserContext } from '../context/usercontext';
import AppbarMenu from '../components/appbarmenu';
import { MAX_WIDTH, TOP_BACKGROUND_GRADIENT } from '../theme/gaimcontrolTheme'
import BottomNavBar from '../components/bottomnavbar';
import {
  ArrowDownward as ArrowDownwardIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import { getCookie, setCookie } from 'typescript-cookie';
import axios from 'axios';
import Journey from '../components/journey';

const isSupported = () =>
  'Notification' in window &&
  'serviceWorker' in navigator &&
  'PushManager' in window
  
// Define the floating animation using keyframes
const float = keyframes`
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
`;

function createSubscription( reg: ServiceWorkerRegistration, username: string ) {
  const subscribeOptions = {
    userVisibleOnly: true,
    applicationServerKey: process.env.REACT_APP_PUBLIC_VAPID_KEY,
  };
  console.log("Subscribing...")
  reg.pushManager.subscribe(subscribeOptions)
  .then( (ps: PushSubscription) => {
    console.debug("Received PushSubscription: ", JSON.stringify(ps));
    return axios.post(process.env.REACT_APP_CHAT_SERVER_URL + '/subscription', ps, {
      headers: {
          "Authorization": process.env.REACT_APP_API_TOKEN,
      },
      params: {
          userName: username
      }
    })
  }).catch( (error) => console.log("Error: " + error) )
}

function subscribeUserToPush( userName: string, setPopupStatus: (status: boolean) => void ) {
  if (!isSupported()) {
    console.debug("Notification permissions are not supported.")
  }

  ReactGA.event({
    category: "Notification subscription",
    action: "Notification permission requested"
  })
  Notification.requestPermission().then((permission) => {
    if (permission !== "granted") {
      ReactGA.event({
        category: "Notification subscription",
        action: "User declined the notification permission"
      })
      return
    }
    ReactGA.event({
      category: "Notification subscription",
      action: "User granted the notification permission"
    })
    return navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/serviceworker.js`)
  })
  .then( (registration) => {
    var serviceWorker: ServiceWorker
    if (registration?.installing) {
      serviceWorker = registration.installing
    } else if (registration?.waiting) {
      serviceWorker = registration.waiting
    } else if (registration?.active) {
      serviceWorker = registration.active
    } else {
      return
    }

    // Ensure the service worker in any state triggers subscription once activated
    const subscribeOnActivation = () => {
      createSubscription(registration, userName);
    };

    if (serviceWorker.state === "activated") {
      subscribeOnActivation()
    } else {
      serviceWorker.addEventListener("statechange", event => {
        const target = event.target as ServiceWorker;
        if (target.state === "activated") {
          subscribeOnActivation()
        }
      })
    }
  })
  .catch( (error) => {
    console.error("Error subscribing: ", error);
  })
  .finally( () => {
    setPopupStatus(false)
  })
}


function JourneyPage() {
  const { user, setUser, setUserLogData } = useContext(UserContext);
  const [subscribeNotifications, setSubscribeNotifications] = useState<boolean>(false)
  const [backdropOpen, setBackdropOpen] = useState<boolean>(false)
  const [fabPosition, setFabPosition] = useState<{ top: number; left: number }>({ top: 0, left: 0 });
  const firstBoxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if ((user?.currentBlock === 0) && (getCookie("firstTime") !== "false")) {
      setBackdropOpen(true)
    }
    updatePosition();
    // Add event listener to ensure correct positioning on resize or scroll
    window.addEventListener('resize', updatePosition);
    window.addEventListener('scroll', updatePosition);

    if (isSupported() && user && user.currentBlock && user.currentBlock > 0) {
      if (Notification.permission === "default") {
        setSubscribeNotifications(true)
      } else {
        setSubscribeNotifications(false)
        if (Notification.permission === "granted" && user.details && !user.details.subscribed) {
          subscribeUserToPush(user.name, setSubscribeNotifications)
        }
      }
    }
    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('resize', updatePosition);
      window.removeEventListener('scroll', updatePosition);
    };
  }, []);

  // Function to update position of Fab
  const updatePosition = () => {
    if (firstBoxRef.current) {
      const rect = firstBoxRef.current.getBoundingClientRect();
      setFabPosition({
        top: rect.top + window.scrollY,
        left: (rect.right + rect.left) / 2 + window.scrollX
      });
    }
  };

  const closeBackdrop = () => {
    setBackdropOpen(false);
    setCookie("firstTime", "false")
  }

  return (
    <Box
      alignItems={'center'}
      display={'flex'}
      flexDirection={'column'}
      sx={{
        height: '100%',
        width: '100%',
      }}
    >
      <AppbarMenu/>
      <Box display={"flex"} flexDirection={"column"} sx={{width: "100%", maxWidth: MAX_WIDTH}}>
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          mr={0}
          p={1}
          sx={{
            display: subscribeNotifications ? "flex" : "none",
            background: TOP_BACKGROUND_GRADIENT,
          }}
        >
          <Typography variant='body2' flexGrow={1} pr={2}>Click on the button to enable notifications: quote of the day, reminders, and tips:</Typography>
          <Button
            variant='contained'
            sx={{ paddingX: 4 }}
            onClick={() => subscribeUserToPush(user?.name || "", setSubscribeNotifications)}
          >
            Enable
          </Button>
          <Box
            alignSelf={"flex-start"} 
            ml={2}
            p={0}
            onClick={() => {
              setSubscribeNotifications(false)
              ReactGA.event({
                category: "Notification subscription",
                action: "User hid the notification subscription request"
              })
            }}
            sx={{cursor: 'pointer'}}
          >
            <CloseIcon fontSize={'small'}/>
          </Box>
        </Box>
      </Box>
      <Journey ref={firstBoxRef}/>
      <BottomNavBar value={0}/>
      <Backdrop open={backdropOpen} onClick={closeBackdrop} transitionDuration={500}>
        <Box
          sx={{
            position: 'absolute',
            top: fabPosition.top,
            left: fabPosition.left,
            animation: `${float} 1.5s ease-in-out infinite`,
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
          }}
        >
          <ArrowDownwardIcon color='primary'/>
          <Typography ml={1}>Start by clicking here!</Typography>
        </Box>
      </Backdrop>
    </Box>
  );
}

export default JourneyPage
