import { Box, Stack, Typography } from "@mui/material";
import {
  Forum as ForumIcon,
  EditNote as EditNoteIcon,
  LockOutlined as LockIcon,
  Check as CheckIcon,
  Flag as FlagIcon,
  HourglassTop as HourglassTopIcon,
} from '@mui/icons-material';

import { useNavigate } from "react-router-dom";
import Pluralize from 'pluralize';

import { ProtoBlock } from "../prototypes"

const ICON_SIZE = '24px'
const ICON_COLOR = '#44B9AC'

function timeUntilTimestamp( ts: number ) : string {
  const diff = ts - Math.floor(Date.now() / 1000);

  if (diff <= 0) {
    return `${ts}`
  }

  const days = Math.floor(diff / 86400);
  if (days > 0) {
    return `${days} ${Pluralize('day', days)}`
  }

  const hours = Math.floor((diff % 86400) / 3600);
  if (hours > 0) {
    return `${hours} ${Pluralize('hour', hours)}`
  }

  const minutes = Math.floor((diff % 3600) / 60);
  if (minutes > 0) {
    return `${minutes} ${Pluralize('minute', minutes)}`
  }

  const seconds = (diff % 60);
  if (seconds > 0) {
    return `${seconds} ${Pluralize('second', seconds)}`
  }

  return "";
}

interface DailyCourseItemProps {
  block: ProtoBlock,
  onClick?: React.MouseEventHandler<HTMLDivElement>
}
  
function DailyCourseItem( props: DailyCourseItemProps ) {
  const navigate = useNavigate();

  return (
  <Stack
    direction={"row"}
    spacing={2}
    flexGrow={1}
    px={"10px"}
    alignItems={"center"}
    sx={{
      backgroundColor: "#3cb179",
      borderRadius: "28px",
      cursor: props.block.status === "available" ? 'pointer' : "auto",
    }}
    onClick = {(props.block.status != "available" ? undefined : 
      props.block.type === "session" ? () => navigate("/chat") : () => navigate(props.block.url || ""))}
  >
    <Box
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      ml={1}
      sx={{
        backgroundColor: '#EAF2F0',
        width: `40px`,
        height: `40px`,
        borderRadius: "50%"
      }}
    >
      {
        props.block.name === "" ? <FlagIcon sx={{color: ICON_COLOR, fontSize: ICON_SIZE}} /> :
        props.block.status === "locked" ? <LockIcon sx={{color: ICON_COLOR, fontSize: ICON_SIZE}} /> :
        props.block.status === "completed" ? <CheckIcon sx={{color: ICON_COLOR}} /> :
        props.block.status === "cooldown" ? <HourglassTopIcon sx={{color: ICON_COLOR, fontSize: ICON_SIZE}} /> : 
        props.block.type === "session" ? <ForumIcon sx={{color: ICON_COLOR, fontSize: ICON_SIZE}} /> : 
        <EditNoteIcon sx={{color: ICON_COLOR, fontSize: ICON_SIZE}} />
      }
    </Box>
    <Box flexGrow={1} padding={1}>
        <Typography
          variant='body1'
          sx={{
            color: "#0f291e",
            fontSize: "14px",
            lineHeight: "20px",
            opacity: props.block.status === "cooldown" ? 0.65 : 1,
          }}
        >
          {props.block.name}
        </Typography>
        <Typography variant='body1' sx={{color: "#0f291e", fontSize: "12px", lineHeight: "20px"}}>
          { props.block.status !== "cooldown" ?
            (props.block.pointsType1 + " min") :
            (props.block.cooldownOpeninigTS ? `Opens in ${timeUntilTimestamp(props.block.cooldownOpeninigTS)}` : "Will be available later")
          }
        </Typography>
    </Box>
  </Stack>
  )
}

export default DailyCourseItem;

