import axios from "axios"
import DateToString from "./dateformatting"
import { UserLogData, ProtoUser, UserLogEntry } from "../prototypes"

const saveUserGambling = (
  gambled: boolean,
  amount: number,
  date: Date,
  user: ProtoUser,
  setUser: (user: ProtoUser) => void,
  setUserLogData: (data: { [key: string]: UserLogEntry }) => void,
  finalize?: () => void ) => {

  axios.put(process.env.REACT_APP_CHAT_SERVER_URL + "/user-log", {
      date: DateToString(date),
      gambled: gambled,
      outcome: gambled ? amount : 0,
    },
    {
      headers: {
        "Authorization": process.env.REACT_APP_API_TOKEN,
      },
      params: {
        userName: user?.name,
        recordType: 'gambling',
      }
    }
  ).then( () => {
  return axios.get<ProtoUser>(process.env.REACT_APP_CHAT_SERVER_URL + `/courses?userName=` + user?.name, {
    headers: {
      "Authorization": process.env.REACT_APP_API_TOKEN
    }})
  }).then( response => {
    setUser(response.data)
  }).then( () => {
    return axios.get<UserLogData>(process.env.REACT_APP_CHAT_SERVER_URL + "/user-log", {
      headers: {
          "Authorization": process.env.REACT_APP_API_TOKEN,
      },
      params: {
          userName: user?.name
    }})
  }).then( data => {
    setUserLogData(data.data.logEntries ? data.data.logEntries : {});
  }).catch(error => {
    console.log(error)
  }).finally( () => {
    finalize && finalize()
  })
}

export default saveUserGambling
