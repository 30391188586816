import { Backdrop, Box, Button, CircularProgress, IconButton, Stack, Typography, useTheme } from "@mui/material";
import AppbarMenu from "../components/appbarmenu";
import { MAX_WIDTH, TOP_BACKGROUND_GRADIENT } from "../theme/gaimcontrolTheme";
import BottomNavBar from "../components/bottomnavbar";
import CalendarStrip from "../components/calendarstrip";
import { useContext, useEffect, useState } from "react";
import getQuoteOfTheDay from "../helpers/qotd";
import { UserContext } from "../context/usercontext";
import { useNavigate, useSearchParams } from "react-router-dom";
import DateToString from "../helpers/dateformatting";
import DailyGamblingRecordTaskItem from "../components/dailygamblingrecordtaskitem";
import DailyMoodRecordTaskItem from "../components/dailymoodtaskitem";
import saveUserMood from "../helpers/saveusermood";
import DailyCourseItem from "../components/dailycourseitem";

import { keyframes } from '@emotion/react';

import {
  Zoom
} from "react-awesome-reveal";
import saveUserGambling from "../helpers/saveusergambling";
import {
  Map as MapIcon
} from "@mui/icons-material";

// Define the keyframes for the animation
const hiThere = keyframes`
  90% { transform: scale(1); }
  93% { transform: scale(1.01); }
  94%, 96% { transform: rotate(-0.5deg) scale(1.01); }
  95% { transform: rotate(0.5deg) scale(1.01); }
  97% { transform: rotate(0deg) scale(1.01); }
  100% { transform: scale(1); }
`;

function DailyPage() {
  let [searchParams] = useSearchParams();
  const passedDate = searchParams.get("date") ? new Date(searchParams.get("date") as string) : new Date()
  const [selectedDate, setSelectedDate] = useState<Date>(passedDate);
  const [selectedDateString, setSelectedDateString] = useState<string>(DateToString(selectedDate));
  const [num, setNum] = useState<number>(0) // The only purpose of this counter is to force re-render
  const {user, userLogData, setUser, setUserLogData} = useContext(UserContext);
  const [processing, setProcessing] = useState<boolean>(false)
  const theme = useTheme();
  const navigate = useNavigate();

  const todayString = DateToString(new Date())

  useEffect(() => {
    setSelectedDateString(DateToString(selectedDate))
  }, [selectedDate])

  const onGamblingRecordSumbit = ( gambled: boolean, outcome: number ) => { 
    if (user) {
      setProcessing(true)
      saveUserGambling(gambled, outcome, selectedDate, user, setUser, setUserLogData, ()=>setProcessing(false))
    }
  }

  return (
    <Box
      alignItems={'center'}
      display={'flex'}
      flexDirection={'column'}
      flexGrow={1}
      sx={{
        width: '100%',
        background: "linear-gradient(180deg, #21263D 0%, #2D3453 100%)",
        overflowY: 'hidden'
      }}
    >
      <AppbarMenu/>
      <Box
        alignItems={'center'}
        display={'flex'}
        flexDirection={'column'}
        flexGrow={1}
        pb={6}
        sx={{
          width: '100%',
          maxWidth: MAX_WIDTH,
          overflowY: 'auto'
        }}
      >
        {/* Calendar Strip */}
        <CalendarStrip
          selectedDate={selectedDate}
          firstDate={new Date(user ? (user.created ? user.created : user.details && user.details.created ? user.details.created: "") : "")}
          onSelectedDateChange={ date => {
            setSelectedDate(date)
          }}
          userLogData={userLogData || undefined}
        />

        <Box
          display={"flex"}
          flexDirection={"column"}
          py={2}
          sx={{
            width: "100%",
            background: TOP_BACKGROUND_GRADIENT,
          }}
        >
          {/* Daily quote */}
          <Zoom duration={500}>
            <Stack spacing={2} m={2} p={2} alignItems={"center"} sx={{ border: "1px solid #AE8DDD", borderRadius: "24px"}}>
              <Box width={"32px"} component="img" src="/logo_flat_white.svg"/>
              <Typography textAlign={"center"} variant="body1">{getQuoteOfTheDay(selectedDate).text}</Typography>
              <Typography textAlign={"center"} variant="body2">— {getQuoteOfTheDay(selectedDate).author}</Typography>
            </Stack>
          </Zoom>
                    
          {/* Daily tasks */}
          <Stack mt={4} px={3} flexGrow={1}>
              <Stack direction={'row'} alignItems={'center'}>
                <Stack flexGrow={1}>
                  <Typography variant="h6">{selectedDateString === todayString ? "For today" : "Back then"}</Typography>
                  <Typography variant="body2" mb={2}>{selectedDateString === todayString ? 
                    "Here are some things you can work on today" :
                    "Things to remember about that day"}
                  </Typography>
                </Stack>
                <Box
                  display={"flex"}
                  alignItems={'center'}
                  justifyContent={'center'}
                  onClick={() => navigate('/journey')}
                  sx={{
                    borderRadius: '12px',
                    backgroundColor: theme.palette.info.main,
                    p: 1,
                    cursor: 'pointer',
                  }}
                >
                  <MapIcon sx={{color: theme.palette.info.contrastText}} />
                </Box>
              </Stack>
              <Stack spacing={2}>
                <Zoom cascade damping={0.5} triggerOnce duration={250}>
                  {/* Today's curriculum content */ }
                  {
                    (selectedDateString === todayString) &&
                    (user && user.currentBlock < user.blocks.length) &&
                    (user.blocks[user.currentBlock].status === 'available' ? 
                      <Box sx={{ animation: `${hiThere} 10s infinite`}}>
                      <DailyCourseItem block={user.blocks[user.currentBlock]} key={"ci-" + selectedDateString}/>
                      </Box>
                    : 
                      <DailyCourseItem block={user.blocks[user.currentBlock]} key={"ci-" + selectedDateString}/>
                    )
                  }

                  {/* Daily tasks */}
                  <DailyMoodRecordTaskItem
                    record={userLogData && userLogData[selectedDateString] !== undefined ? userLogData[selectedDateString] : undefined}
                    num={num}
                    today={selectedDateString === todayString}
                    key={"mood-" + selectedDateString}
                    onClick={(newMood)=>{
                      var newUserMood = userLogData
                      const stringDate = selectedDateString
                      if (!newUserMood) {
                        newUserMood = {};
                      }
                      if (stringDate in newUserMood){
                        newUserMood[stringDate].mood = newMood;
                      } else {
                        newUserMood[stringDate] = {mood: newMood}
                      }
                      setUserLogData(newUserMood);
                      setNum(currentNum => currentNum + 1);
                      user && saveUserMood(newMood, selectedDate, user, setUser, setUserLogData)
                    }}
                  />
                  <DailyGamblingRecordTaskItem
                    today={selectedDateString === todayString}
                    record={userLogData && userLogData[selectedDateString] !== undefined ? userLogData[selectedDateString] : undefined}
                    key={"glg-" + selectedDateString}
                    onSubmit={onGamblingRecordSumbit}
                  />
                </Zoom>
              </Stack>
            {
              userLogData && userLogData[selectedDateString] !== undefined && userLogData[selectedDateString].completedBlocks &&
              <div>
                <Typography variant="h6" mt={3} mb={2}>What you have completed</Typography>
                <Stack spacing={2}>
                  {
                    userLogData[selectedDateString].completedBlocks?.map((block: number) => (
                      user?.blocks[block] !== undefined && <DailyCourseItem key={block} block={user?.blocks[block]}/>
                    ))
                  }
                </Stack>
              </div>
            }
          </Stack>
        </Box>
      </Box>
      <Backdrop open={processing}><CircularProgress /></Backdrop>
      <BottomNavBar value={0} />
    </Box>
  )
}

export default DailyPage;
