import { BottomNavigation, BottomNavigationAction, Box } from "@mui/material";

import {
  LiveHelpOutlined as QuestionIcon,
  MedicalServicesOutlined as MedicalServicesIcon,
  PersonOutlined as PersonIcon,
  Home as HomeIcon,
  SportsEsports as SportsEsportsIcon,
} from '@mui/icons-material';

import { useNavigate } from "react-router-dom";

import { BOTTOM_NAVBAR_HEIGHT, MAX_WIDTH } from "../theme/gaimcontrolTheme";

interface BottomNavBarProps {
  value?: number
}

function BottomNavBar( props: BottomNavBarProps) {
  const navigate = useNavigate();

  return (
    <Box>
      <Box
        display={"flex"}
        justifyContent={"center"}
        sx= {{
          background: "linear-gradient(0deg, rgba(32,31,31,1) 35px, rgba(32,31,31,0) 60px)",
          position: "fixed", bottom: 0, left: 0, right: 0,
          width: "100%",
        }}
      >
        <BottomNavigation
          showLabels
          value = {props.value}
          sx = {{
            background: "transparent",
            height: BOTTOM_NAVBAR_HEIGHT,
            width: "100%",
            maxWidth: MAX_WIDTH,
          }}
        >
          <BottomNavigationAction onClick={() => {navigate("/")}} sx={{color: "rgb(196, 199, 200)"}} icon={<HomeIcon/>} label="Home"/>
          <BottomNavigationAction onClick={() => {navigate("/game")}} sx={{color: "rgb(196, 199, 200)"}} icon={<SportsEsportsIcon />} label="Game"/>
          <BottomNavigationAction onClick={() => {navigate("/aid")}} sx={{color: "rgb(196, 199, 200)"}} icon={<MedicalServicesIcon/>} label="First Aid"/>
          <BottomNavigationAction onClick={() => {navigate("/ask-me")}} sx={{color: "rgb(196, 199, 200)"}} icon={<QuestionIcon/>} label="Ask me"/>
          <BottomNavigationAction onClick={() => {navigate("/profile")}} sx={{color: "rgb(196, 199, 200)"}} icon={<PersonIcon/>} label="Profile"/>
        </BottomNavigation>
      </Box>
    </Box>
  )
}

export default BottomNavBar;
