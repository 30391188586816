import axios from "axios"
import DateToString from "./dateformatting"
import { ProtoUser, UserLogData, UserLogEntry } from "../prototypes"

const saveUserMood = (
  mood: string,
  date: Date,
  user: ProtoUser,
  setUser: (user: ProtoUser) => void,
  setUserLogData: (data: { [key: string]: UserLogEntry }) => void ) => {
  axios.put(process.env.REACT_APP_CHAT_SERVER_URL + "/user-log", {
    date: DateToString(date),
    mood: mood,
  },
  {
    headers: {
      "Authorization": process.env.REACT_APP_API_TOKEN,
    },
    params: {
      userName: user?.name,
      recordType: 'mood',
    }
  }).then( () => {
  return axios.get<ProtoUser>(process.env.REACT_APP_CHAT_SERVER_URL + `/courses?userName=` + user?.name, {
    headers: {
      "Authorization": process.env.REACT_APP_API_TOKEN
    }})
  }).then( response => {
    setUser(response.data)
  }).then( () => {
    return axios.get<UserLogData>(process.env.REACT_APP_CHAT_SERVER_URL + "/user-log", {
      headers: {
          "Authorization": process.env.REACT_APP_API_TOKEN,
      },
      params: {
          userName: user?.name
    }})
  }).then( data => {
    setUserLogData(data.data.logEntries ? data.data.logEntries : {});
  }).catch(error => {
    console.log(error)
  })
}

export default saveUserMood
