import { Fade, Grid, Slider, Stack, Typography } from "@mui/material";
import { MAX_WIDTH } from "../theme/gaimcontrolTheme";
import { useState } from "react";

interface AnswerSelectorComponentProps {
  hidden?: boolean;
  options: string[];
  onChange?: ( value: number ) => void;
  value: number;
  timeout: number;
}

function AnswerSelectorComponent( props: AnswerSelectorComponentProps ) {
  const [maxValue, setMaxValue] = useState<number>(100)

  const onChange = (event: Event, value: number | number[]) => {
    if (!props.onChange) {
      return
    }
    if (maxValue === (props.options.length - 1)) {
      props.onChange(value as number);
      return
    }
    setMaxValue(props.options.length - 1);
    props.onChange(Math.round((props.options.length - 1) / 2));
  }

  return (
    <Fade in={(props.hidden !== true)} timeout={props.timeout}>
    <Stack
      flexGrow={1}
      alignItems={"center"}
      maxWidth={MAX_WIDTH}
      spacing={3}
      px={6}
    >
      <Slider
        marks={maxValue < 10} onChange={onChange} value={props.value} min={0} max={maxValue} 
        sx={{
          width: `${Math.round(100 - (100 / (props.options.length + 1)))}%`,
          '& .MuiSlider-track': {
            height: "10px",
            border: 'none',
            backgroundImage: "linear-gradient(90deg, #B5FF1B 0%, #FFC331 60.4%, #7D2BE5 100%)"
          },
          '& .MuiSlider-rail': {
            height: "10px",
          }
        }}
      />
      <Grid container spacing={2} columns={props.options.length}>
        {
          props.options.map((option, index) => (
            <Grid item xs={1} key={index}>
              <Typography textAlign={"center"} variant="body2">{option}</Typography>
            </Grid>
          ) )
        }
      </Grid>
    </Stack>
  </Fade>    
  )
}

export default AnswerSelectorComponent;
