import React, { useContext, useState } from 'react';
import { DndProvider, useDrag, useDrop, useDragLayer } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { Backdrop, Box, Button, CircularProgress, Grid, IconButton, Stack, Typography } from '@mui/material';
import { useTheme, alpha } from '@mui/material/styles';
import {
  Cancel as CancelIcon
} from '@mui/icons-material';
import { BACKGROUND_GRADIENT_TRANSPARENT_DO, MAX_WIDTH } from '../../theme/gaimcontrolTheme';
import AppbarMenu from '../../components/appbarmenu';
import FancyMessageBox from '../../components/fancymessagebox';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/usercontext';
import axios from 'axios';
import { EXERCISE_NAME_HABITS, ProtoUser } from '../../prototypes';

const CustomDragLayer: React.FC = () => {
    const {
      isDragging,
      item,
      initialOffset,
      currentOffset,
    } = useDragLayer((monitor) => ({
      item: monitor.getItem(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
  }));

  const theme = useTheme();

  if (!isDragging) {
    return null;
  }

  const layerStyles: React.CSSProperties = {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 1000,
    left: 0,
    top: 0,
  };

  const getItemStyles = (initialOffset: any, currentOffset: any) => {
    if (!initialOffset || !currentOffset) {
      return {
        display: 'none',
      };
    }

    const { x, y } = currentOffset;
    const transform = `translate(${x}px, ${y}px)`;
    return {
      transform,
      WebkitTransform: transform,
    };
  };

  return (
    <div style={layerStyles}>
      <div style={getItemStyles(initialOffset, currentOffset)}>
        <Box sx={{ padding: 2, backgroundColor: theme.palette.primary.main, }}>
          {item.text}
        </Box>
      </div>
    </div>
  );
};


type CardType = {
  id: number;
  text: string;
};

const cardStack: CardType[] = [
  {id: 0, text: 'For excitement'},
  {id: 1, text: 'To get rich'},
  {id: 2, text: 'To feel superior'},
  {id: 3, text: 'To make friends'},
  {id: 4, text: 'To forget my worries'},
  {id: 5, text: 'To feel powerful'},
  {id: 6, text: 'To distract myself from problems'},
  {id: 7, text: 'To avoid people'},
  {id: 8, text: 'To have something to do'},
  {id: 9, text: 'To escape depression or loneliness'},
  {id: 10, text: 'The thrill of winning'},
  {id: 11, text: 'Out of habit'},
]

const Card: React.FC<{ card: CardType; canDrag: boolean }> = ({ card, canDrag }) => {
  const theme = useTheme();

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'CARD',
    item: card,
    canDrag: canDrag,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <Box
      ref={drag}
      sx={{
        opacity: canDrag ? isDragging ? 0.5 : 1 : 0,
        cursor: canDrag ? 'move' : 'default',
        border: '1px solid black',
        borderRadius: '5px',
        padding: 1,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      }}
    >
      <Typography variant='body2' flexGrow={1} sx={{color: theme.palette.primary.contrastText}}>{card.text}</Typography>
    </Box>
  );
};

const DropZone: React.FC<{
  label: string;
  image: string;
  cards?: CardType[];
  onDrop: (item: CardType) => void;
  onDelete: (card : CardType) => void;
  color: string }> = ({ label, image, cards, onDrop, onDelete, color }) => {
  const theme = useTheme();
  const [, drop] = useDrop(() => ({
    accept: 'CARD',
    drop: (item: CardType) => {
      onDrop(item);
    },
  }));

  return (
    <Stack
      ref={drop}
      flexGrow={1}
      p={1}
      alignItems={'center'}
      sx={{
        border: `1px dotted grey`,
        borderRadius: '20px',
        background: `${BACKGROUND_GRADIENT_TRANSPARENT_DO}, url('${image}')`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <Typography>{label}</Typography>
      {
        cards && cards.length > 0 && (
           <div style={{ width: '100%' }}>
            { cards.map((card, index) => (
              <Stack
                direction={'row'}
                alignItems={'center'}
                sx={{
                  padding: 1,
                  margin: 0.5,
                  borderRadius: '5px',
                  backgroundColor: theme.palette.primary.main,
                }}
              >
                <Typography variant='body2' flexGrow={1} sx={{color: theme.palette.primary.contrastText}}>{card.text}</Typography>
                <IconButton sx={{color: theme.palette.primary.contrastText}} size='small' onClick={()=>onDelete(card)}><CancelIcon/></IconButton>
              </Stack>
            ))}
          </div>    
        )
      }
    </Stack>
  );
};

const ReasonsToGamble: React.FC = () => {
  const [cards, setCards] = useState(cardStack);
  const [alwaysCards, setAlwaysCards] = useState<CardType[]>([]);
  const [sometimesCards, setSometimesCards] = useState<CardType[]>([]);
  const [neverCards, setNeverCards] = useState<CardType[]>([]);
  const theme = useTheme();

  const [processing, setProcessing] = useState<boolean>(false)
  const [finalDialog, setFinalDialog] = useState<boolean>(false)
  const navigate = useNavigate()
  const { user, setUser } = useContext(UserContext);


  const handleAlwaysDrop = (card: CardType) => {
    setAlwaysCards((prev) => [...prev, card]);
    setCards((prev) => prev.filter((c) => c.id !== card.id));
  };

  const handleSometimesDrop = (card: CardType) => {
    setSometimesCards((prev) => [...prev, card]);
    setCards((prev) => prev.filter((c) => c.id !== card.id));
  };

  const handleNeverDrop = (card: CardType) => {
    setNeverCards((prev) => [...prev, card]);
    setCards((prev) => prev.filter((c) => c.id !== card.id));
  };

  const onAlwaysDelete = ( card: CardType ) => {
    setAlwaysCards((prev) => prev.filter((c) => c.id !== card.id));
    setCards((prev) => [card, ...prev]);
  }

  const onSometimesDelete = ( card: CardType ) => {
    setSometimesCards((prev) => prev.filter((c) => c.id !== card.id));
    setCards((prev) => [card, ...prev]);
  }

  const onNeverDelete = ( card: CardType ) => {
    console.log('onDelete', card);
    setNeverCards((prev) => prev.filter((c) => c.id !== card.id));
    setCards((prev) => [card, ...prev]);
  }

  const onDone = () => {
    setProcessing(true)
    axios.put(process.env.REACT_APP_CHAT_SERVER_URL + "/exercise", {
        often: alwaysCards.map(item => item.text),
        sometimes: sometimesCards.map(item => item.text),
        never: neverCards.map(item => item.text)
    },
    {
      headers: {
        "Authorization": process.env.REACT_APP_API_TOKEN,
        "Content-Type": "application/json"
      },
      params: {
        userName: user?.name,
        exerciseName: encodeURIComponent(EXERCISE_NAME_HABITS),
      }
    }).then(
      response => {
        return axios.get<ProtoUser>(process.env.REACT_APP_CHAT_SERVER_URL + `/courses?userName=` + user?.name, {
          headers: {
            "Authorization": process.env.REACT_APP_API_TOKEN
          }      
        })
    }).then(
      response => {
        setUser(response.data)
        setFinalDialog(true)
    }).catch(error => {
      console.log(error)
    }).finally(() => {
      setProcessing(false)
    })
  }  

  return (
    <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
      <CustomDragLayer />
      <Stack
        maxWidth={MAX_WIDTH} 
        width={"100%"}
        flexGrow={1}
        alignSelf={'center'}
      >
        <Stack my={2} alignItems={"center"} width={"100%"} alignSelf={'center'}>
          <Typography variant="h4" textAlign={'center'} mb={2}>How often does this drive your gambling? Where does it go? Drag it to its place.</Typography>
          <Box>
            {cards.length > 0 ?
              <Card key={cards[0].id} card={cards[0]} canDrag={true}/>
            : <Button variant='contained' color='success' sx={{paddingX: 6}} onClick={onDone}>Done</Button>}
          </Box>
        </Stack>
        <Grid flexGrow={1} container spacing={2} p={1} width={"100%"} maxWidth={MAX_WIDTH} alignSelf={'center'}>
          <Grid item xs={6} sm={4} display={'flex'} >
            <DropZone
              label="Often"
              onDrop={handleAlwaysDrop}
              onDelete={onAlwaysDelete}
              cards={alwaysCards}
              color={alpha(theme.palette.success.main, 0.25)}
              image="/exercises/reasons/wallet-svgrepo-com.svg"
            />
          </Grid>
          <Grid item xs={6} sm={4} display={'flex'}>
            <DropZone
              label="Sometimes"
              onDrop={handleSometimesDrop}
              onDelete={onSometimesDelete}
              cards={sometimesCards}
              color={alpha(theme.palette.info.main, 0.25)}
              image="/exercises/reasons/backpack.svg"
            />
          </Grid>
          <Grid item xs={3} sx={{ display: { xs: 'block', sm: 'none' } }}>
          </Grid>
          <Grid item xs={6} sm={4} display={'flex'}>
            <DropZone
              label="Never"
              onDrop={handleNeverDrop}
              onDelete={onNeverDelete}
              cards={neverCards}
              color={alpha(theme.palette.warning.main, 0.25)}
              image="/exercises/reasons/trash-can-svgrepo-com.svg"
            />
          </Grid>
        </Grid>
      </Stack>
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}
        open={processing}
      >
        <CircularProgress/>
      </Backdrop>
      <FancyMessageBox
        message={"Thinking about what items you carry, and what makes you carry those items, is one step towards changing your load"}
        title='Well done.'
        display={finalDialog}
        heartsEarned={user?.blocks[user.currentBlock - 1]?.pointsType1 || 0}
        onClose={() => {
          setFinalDialog(false);
          navigate("/")
        }}
      />
    </DndProvider>
  );
};

const messages: string[] = [
  "Let's keep preparing. Over the course of this journey, we'll be exploring what drives you to gamble.",
  "Some things might always be on hand - pack them into your wallet " +
    "or pocket for now. Others occur occasionally, so add them to your " +
    "backpack. And some just don't apply to you at all... feel free to leave them behind.",
]

const ReasonsToGambleExercise: React.FC = () => {
  const [stage, setStage] = useState<number>(0);

  return (
    <Stack
      alignSelf={"center"}
      alignItems={"center"}
      justifyContent={"center"}
      height={"100%"}
      maxWidth={MAX_WIDTH}
      padding={2}
      sx={{
        overflowY: 'auto',
        backgroundImage: "url('/background_spots.png'), linear-gradient(180deg, #21263D 0%, #2D3453 100%)",
        backgroundRepeat: 'repeat-y',
        backgroundSize: "100% auto",
        backgroundAttachment: 'local',
      }}
    >
      <AppbarMenu />
      <Typography variant='h3' my={3}>Let's think how we pack</Typography>
      {stage < 2 ?
        <Stack flexGrow={1} alignItems={"center"}>
          <Box component="img" maxHeight={"300px"} maxWidth={"300px"} src="/exercises/reasons/backpack.svg" my={6}/>
          <Box
            sx= {{
              border: '',
              backgroundColor: '#E2E2E2',
              borderRadius: '20px'
            }}
            p={2}
          >
            <Typography color={"black"} variant="body1">{messages[stage % messages.length]}</Typography>
          </Box>
          <Box flexGrow={1}/>
          <Button
            variant="contained"
            onClick={()=>setStage(stage + 1)}
            sx={{marginY: 2, paddingX: '60px'}}
          >
            Next
          </Button>
        </Stack>
      : stage === 3 ? <ReasonsToGamble />:
        <ReasonsToGamble />}
    </Stack>
  );
}

export default ReasonsToGambleExercise;