import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { BOTTOM_NAVBAR_HEIGHT, MAX_WIDTH, TOP_BACKGROUND_GRADIENT } from "../theme/gaimcontrolTheme";
import AppbarMenu from "../components/appbarmenu";
import BottomNavBar from "../components/bottomnavbar";
import StatsCardComponent from "../components/statscard";
import GamblingLogGraph from "../components/gamblingloggraph";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../context/usercontext";
import DateToString, { DAYS_IN_CALENDAR } from "../helpers/dateformatting";
import { UserLogEntry } from "../prototypes";
import { removeCookie } from 'typescript-cookie'

interface UserStats {
  daysLogged?: number;
  daysLoggedPercentage?: number;
  gamblingFreeStreak?: number;
  gamblingFreePercentage?: number;
}

function ProfilePage() {
  const [loading, setLoading] = useState<boolean>(true)
  const {user, userLogData, setUser} = useContext(UserContext);
  const [userStats, setUserStats] = useState<UserStats>();

  const calculateStats = ( data: { [key: string]: UserLogEntry }): UserStats => {
    const today = new Date();
    const dates = Array.from({ length: DAYS_IN_CALENDAR }, (_, i) => {
      return new Date(today.getTime() - (i) * 24 * 60 * 60 * 1000);
    });
    const lastRecord = data[DateToString(today)];

    var reported = (lastRecord === undefined ? 0 : 1);
    var streak = (lastRecord === undefined ? 0 : lastRecord.gambled ? 0 : 1);
    var nonGambled = streak;
    var onStreak: boolean = (lastRecord === undefined ? true : !lastRecord.gambled);

    for (let i = 1; i < dates.length; i++) {
      const date = DateToString(dates[i])
      const record = data[date];

      if (record === undefined) {
        onStreak = false;
        continue;
      }
      reported++
      if (record.gambled) {
        onStreak = false;
      }
      else {
        onStreak && streak++;
        nonGambled++;
      }
    }

    return {
      daysLogged: reported,
      daysLoggedPercentage: Math.round((reported / DAYS_IN_CALENDAR) * 100),
      gamblingFreeStreak: streak,
      gamblingFreePercentage: reported === 0 ? 0 : Math.round((nonGambled / reported) * 100),
    }
  }

  function userLogout( ) {
    removeCookie("userName")
    setUser(null);
  }

  useEffect(() => {
    if (user === null || userLogData === null) {
      return;
    }
    setUserStats(calculateStats(userLogData));
    setLoading(false);
  }, [user, userLogData])

  return (
    <Box
      flexGrow={1}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      width={'100%'}
      height={'100%'}
      sx={{
        background: "linear-gradient(180deg, #21263D 0%, #2D3453 100%)",
        overflowY: 'hidden'
      }}
    >
      <AppbarMenu />
      <Stack
        maxWidth={MAX_WIDTH}
        width={'100%'}
        alignSelf={"center"}
        flexGrow={1}
        pb={BOTTOM_NAVBAR_HEIGHT}
        sx={{
          overflowY: 'auto',
        }}
      >
        <Typography mx={'20px'} gutterBottom variant="h3">Over last {DAYS_IN_CALENDAR} days</Typography>
        <Stack sx={{background: TOP_BACKGROUND_GRADIENT}} px={'20px'} py={'20px'}>
          <Grid
            spacing={2}
            container
          >
            <Grid item xs={6}>
              <StatsCardComponent
                loading={loading}
                icon={'edit_calendar'}
                value={userStats?.daysLogged !== undefined ? userStats?.daysLogged?.toString() : "--"}
                label={"Days Logged"}
                textColor="white"
                color="#44B9AC"
              />
            </Grid>
            <Grid item xs={6}>
              <StatsCardComponent
                loading={loading}
                icon={'edit_note'}
                value={userStats?.daysLoggedPercentage !== undefined ? `${userStats?.daysLoggedPercentage}%` : "--"}
                progress={userStats?.daysLoggedPercentage}
                label={"Days Logged"}
                textColor="white"
                color="#FB8728"
              />
            </Grid>
            <Grid item xs={6}>
              <StatsCardComponent
                loading={loading}
                icon={'border_color'}
                value={userStats?.gamblingFreeStreak !== undefined ? `${userStats?.gamblingFreeStreak}d` : "--"}
                label={"Bet-free streak"}
                textColor="white"
                color="#EAB308"
                explanation="Unlogged days break streak"
              />
            </Grid>
            <Grid item xs={6}>
              <StatsCardComponent
                loading={loading}
                icon={'track_changes'}
                value={userStats?.gamblingFreePercentage !== undefined ? `${userStats?.gamblingFreePercentage?.toString()}%` : "--"}  
                progress={userStats?.gamblingFreePercentage}
                label={"Bet free days"}
                textColor="white"
                color="#737373"
                explanation="Percentage of logged days without gambling"
              />
            </Grid>
          </Grid>
        </Stack>
        <GamblingLogGraph
          userLogData={userLogData ? userLogData : undefined}
        />
        <Button onClick={()=>userLogout()} sx={{m: 4}}>Logout</Button>
        <BottomNavBar value={4}/>
      </Stack>
    </Box>
  )
}

export default ProfilePage;
