import React, { useContext, useEffect, useRef } from "react";

import { UserContext } from '../context/usercontext';
import { Box, Typography } from "@mui/material";
import CourseItem from "./courseitem";
import { useNavigate } from "react-router-dom";
import { MAX_WIDTH, TOP_BACKGROUND_GRADIENT } from "../theme/gaimcontrolTheme";

interface JourneyProps {
    firstBoxRef?: React.RefObject<HTMLDivElement>;
    hidden?: boolean;
}

const Journey = React.forwardRef<HTMLDivElement, JourneyProps> ( (props, ref) => {
    // const theme = useTheme();
    const { user } = useContext(UserContext);
    const scrollRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();

    useEffect(() => {
        if ((user?.currentBlock !== 0) && scrollRef.current) {
          scrollRef.current.scrollIntoView({ behavior: "auto" });
        }
    }, [])

    return (
      <Box
        display={props.hidden === true ? 'none' : 'flex'}
        flexDirection={'column'}
        sx={{
          width: '100%',
          backgroundImage: "url('/background_spots.png')",
          backgroundRepeat: 'repeat-y',
          backgroundSize: "100% auto",
          backgroundAttachment: 'local',
          maxWidth: MAX_WIDTH,
          overflowY: 'auto',
        }}
      >
        <Box width={"100%"} sx={{background: TOP_BACKGROUND_GRADIENT}}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            m={2}
          >
            <Box mr={2} flexGrow={1}>
              <Typography variant='body2' mt={1}>The first step is the most important. Explore the power of new beginnings!</Typography>
            </Box>
            <Box width={"140px"} component="img" src="/mental_health2.png" />
          </Box>
        </Box>

        {
          user?.blocks?.map((block, index) => {
            return (
              <Box display={'flex'} flexDirection={'column'} key={index}>
                <CourseItem
                  type={block.type}
                  name={block.name}
                  // At the moment, as a proxy for duration, I use number of hearts earned by an exercise
                  duration={block.pointsType1}
                  alignment={index % 2 ? "left" : "right"}
                  status={block.status}
                  cooldownTS={block.cooldownOpeninigTS}
                  ref={index === 0 ? ref : undefined}
                  onClick={block.status !== "available" ? undefined : block.type === "session" ? () => {navigate("/chat")} : () => {navigate(block.url || "")}}
                />
                <Box
                  sx={{
                      backgroundSize: '100% 100%',
                      backgroundImage: index % 2 ? "url('/line_l2r.svg')" : "url('/line_r2l.svg')",
                      width: "60%",
                      height: "100px"
                  }}
                  alignSelf={"center"}
                  ref={user.currentBlock === (index + 1) ? scrollRef : undefined}
                />
              </Box>
            )
          })
        }
      </Box>
    )
})

export default Journey;
