import {
  Box,
  Stack,
  Typography
} from "@mui/material";

import {
  Check as CheckIcon,
  NoteAdd as NoteAddIcon,
} from '@mui/icons-material';
import { UserLogEntry } from "../prototypes";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../context/usercontext";

const CHECK_ICON_SIZE = 24

interface DailyGamblingRecordTaskItemProps {
    onSubmit?: ( gambled: boolean, outcome: number ) => void;
    today?: boolean;
    record?: UserLogEntry
}

function DailyGamblingRecordTaskItem( props: DailyGamblingRecordTaskItemProps ) {
  const [status, setStatus] = useState<'abstained' | 'lost' | 'won' | undefined>(undefined)
  const [outcome, setOutcome] = useState<number | undefined>(undefined)
  const {user} = useContext(UserContext);

  useEffect(() => {
    if (props.record === undefined || props.record.gambled === undefined) {
      setStatus(undefined)
      setOutcome(undefined)
    } else {
      setStatus(props.record.gambled === false ? 'abstained' :
        (props.record.gamblingOutcome && props.record.gamblingOutcome > 0) ? 'won' : 'lost')
      setOutcome(props.record.gamblingOutcome)
    }
  }, [props.record])

  const setRecord = ( gambled: boolean, outcome: number ) => {
    props.onSubmit && props.onSubmit(gambled, outcome)
    if (!gambled) {
      setStatus('abstained')
      setOutcome(undefined)
    } else {
      setStatus(outcome > 0 ? 'won' : 'lost')
      setOutcome(outcome)
    }
  }

  const onGambled = ( selection: 'lost' | 'won' ) => {
    const outcome = prompt(selection === 'lost' ? "How much did you lose?" : "How much did you win?")

    if (outcome === null) {
      return
    }
    const outcomeNum = parseInt(outcome)
    if (Number.isNaN(outcomeNum)) {
      alert("Please enter a number only")
      return
    }
    if (outcomeNum < 0) {
      alert("Please don't enter negative number")
      return
    }
    setRecord(true, selection === 'won' ? outcomeNum : -outcomeNum)
  }

  return (
    <Stack
      direction={"row"}
      spacing={2}
      flexGrow={1}
      px={'10px'}
      alignItems={"center"}
      sx={{
        backgroundColor: "#ff802b",
        borderRadius: "28px"
      }}
    >
      {/* A circle with an icon */}
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        ml={1}
        sx={{
          backgroundColor: '#EAF2F0',
          width: `40px`,
          height: `40px`,
          borderRadius: "50%"
        }}
      >
        {
          props.record === undefined || props.record.gambled === undefined ?
          <NoteAddIcon sx={{fontSize: `${CHECK_ICON_SIZE}px`, color: "#FB8C00"}} /> :
          <CheckIcon sx={{fontSize: `${CHECK_ICON_SIZE}px`, color: "#44B9AC"}} />
        }
      </Box>

      {/* Text and action part */}
      <Box flexGrow={1} padding={1}>
        {/* Top line of the text */}
        {  
          status === undefined ? 
            <Typography variant='body1' sx={{color: "#391a03", fontSize: "14px"}}>
                Did you gamble {props.today ? "today?" : "back then?"}
            </Typography>
          : status === 'abstained' ?
            <Typography variant='body1' sx={{color: "#391a03", fontSize: "14px"}}>
              Well done{user && user.nickname ? `, ${user.nickname}!` : "!"} You stayed away!
            </Typography>
          :
          <Typography variant='body1' sx={{color: "#391a03", fontSize: "14px"}}>
            You {status === 'won' ? ` won ${outcome}` : ` lost ${outcome && -outcome}. Don't chase it!`}
          </Typography>
        }
        {/* Bottom line of the text */}
        <Stack direction={"row"} spacing={2} alignItems={"center"} mb={0.5}>
          <Typography
            textAlign={'center'}
            variant='caption'
            onClick={() => setRecord(false, 0)}
            sx={{
              color: status === 'abstained' ? "#ffffff" : "#391a03",
              backgroundColor: status === 'abstained' ? "#391a03" : undefined,
              paddingX: 2,
              paddingY: 0.5,
              // border: '1px solid #391a03',
              borderRadius: 5,
              cursor: "pointer"
            }}
          >
            No
          </Typography>
          <Typography
            textAlign={'center'}
            variant='caption'
            onClick={() => onGambled('won')}
            sx={{
              color: status === 'won' ? "#ffffff" : "#391a03",
              backgroundColor: status === 'won' ? "#391a03" : undefined,
              paddingX: 2,
              paddingY: 0.5,
              // border: '1px solid #391a03',
              borderRadius: 5,
              cursor: "pointer"
            }}
          >
            Won
          </Typography>
          <Typography
            textAlign={'center'}
            variant='caption'
            onClick={() => onGambled('lost')}
            sx={{
              color: status === 'lost' ? "#ffffff" : "#391a03",
              backgroundColor: status === 'lost' ? "#391a03" : undefined,
              paddingX: 2,
              paddingY: 0.5,
              // border: '1px solid #391a03',
              borderRadius: 5,
              cursor: "pointer"
            }}
          >
            Lost
          </Typography>
        </Stack>
      </Box>
  </Stack>)
}

export default DailyGamblingRecordTaskItem
