import { Box, Stack, Typography } from "@mui/material";

interface SelectorBarPros {
  options: string[];
  selected: number;
  width?: number | string
  onChange?: (index: number) => void;
}

function SelectorBar( props: SelectorBarPros ) {
  return (
  <Stack
    direction={"row"}
    width={props.width}
    alignItems={"center"}
    justifyContent={"center"}
    m={2}
    sx={{
      backgroundColor: "#ffffff30",
      borderRadius: "400px",
    }}
  >
    {
      props.options.map((option, index) => (
        <Box
          key={option}
          p={2}
          sx={{
            backgroundColor: index === props.selected ? "rgb(255, 255, 255)" : "transparent",
            borderRadius: "400px",
          }}
          onClick={() => props.onChange?.(index)}
        >
          <Typography fontWeight={"bold"} variant="body2" sx={{color: index === props.selected ? "black" : "white"}}>{option}</Typography>
        </Box>
      ))
    }
  </Stack>)
}

export default SelectorBar;