import {useContext, useState, useEffect} from 'react';
import axios from 'axios';

import {
    Box,
} from '@mui/material';

import AppbarMenu from '../components/appbarmenu';
import { UserContext } from '../context/usercontext';
import { ProtoSessionTranscript, ProtoUser } from '../prototypes';
import ChatMessageList, { ChatMessage } from '../components/chatmessagelist';
import FancyMessageBox from '../components/fancymessagebox';
import { getCookie } from 'typescript-cookie';
import { useNavigate } from 'react-router-dom';
import BottomNavBar from '../components/bottomnavbar';
import { MAX_WIDTH } from '../theme/gaimcontrolTheme';

function ConvertMessages( session: ProtoSessionTranscript ): ChatMessage[] {
    var result = [] as ChatMessage[]

    session.messages.forEach((message) => {
        result.push({
            message: message.text,
            incoming: message.sender === "Bot"
        })
    } )
    return result
}

const SessionChat: React.FC = () => {
  const { user, setUser } = useContext(UserContext);
  const [ chatMessages, setMessages ] = useState([] as ChatMessage[])
  const [ typingIndicator, setTypingIndicator] = useState(false)
  const [ finished, setFinished] = useState(false)
  const [ celebration, setCelebration ] = useState<boolean>(false)
  const navigate = useNavigate();

  /////////////////////////////////////////////////////////////////////////
  // Initialization: reading or creating the session.
  /////////////////////////////////////////////////////////////////////////
  useEffect(() => {
      if (user == null || user.blocks == null )
          return;

      // Try loading exinsting session first
      axios.get<ProtoSessionTranscript>(process.env.REACT_APP_CHAT_SERVER_URL + "/sessions", {
          headers: {
              "Authorization": process.env.REACT_APP_API_TOKEN,
          },
          params: {
              userName: user.name
          }
      }).then( response => {
          setMessages(ConvertMessages(response.data))
          setCelebration((response.data.status && response.data.status !== "available") as boolean)
      }).catch(error => {
          // If not found, let's create the new session
          if (!error.response || error.response.status !== 404) {
              console.log("Unexpected error when reading the session.")
              return;
          }
          axios.post<ProtoSessionTranscript>(process.env.REACT_APP_CHAT_SERVER_URL + "/sessions", { }, {
              headers: {
                  "Authorization": process.env.REACT_APP_API_TOKEN,
              },
              params: {
                  userName: user.name
              }
          }).then( response => {
              setMessages(ConvertMessages(response.data))
              setCelebration((response.data.status && response.data.status !== "available") as boolean)
          }).catch(error => {
              console.log("Error creating the session.")
          })
      })
  },[user])

  /////////////////////////////////////////////////////////////////////////
  // Handling sending the message
  /////////////////////////////////////////////////////////////////////////
  function handleSendMessage( text: string ) {
      var message = { sender: "User", text: text }
      var newChatMessages = chatMessages
      newChatMessages.push({
          message: text,
          incoming: false
      } as ChatMessage)
      setMessages(newChatMessages.slice())

      setTypingIndicator(true)
      axios.patch(process.env.REACT_APP_CHAT_SERVER_URL + "/sessions", message, {
          headers: {
              "Authorization": process.env.REACT_APP_API_TOKEN,
          },
          params: {
              userName: user?.name
          }
      }).then( response => {
        newChatMessages.push({
            message: response.data.text,
            incoming: true
        } as ChatMessage)
        setMessages(newChatMessages.slice())
        if (response.status === 202) {
            setCelebration(true)
            // If the session is over, we need to update the user info -
            // so, requesting it from the server
            const code = getCookie("userName")
            axios.get<ProtoUser>(process.env.REACT_APP_CHAT_SERVER_URL + `/courses?userName=` + code, {
              headers: {
                "Authorization": process.env.REACT_APP_API_TOKEN,
              }      
            }).then(response => {
              setUser(response.data);
            }).catch(error => {
              console.log("Error updating the user.")
            }).finally(() => {
            })
        }

      }).catch(error => {
          console.log("Error sending the message.")
      }).finally( () =>
          {setTypingIndicator(false)}
      )
  }

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      sx={{
        overflowY: "clip",
        height: "100%",
        width: "100%",
      }}
    >
      <AppbarMenu title="LET'S TALK"/>
      <Box flexGrow={1} sx={{width: "100%", maxWidth: MAX_WIDTH}}>
        <ChatMessageList
          messages={chatMessages}
          onSubmit={handleSendMessage}
          disabled={typingIndicator || finished}
          typing={typingIndicator}
        />
      </Box>
      <BottomNavBar />
      <FancyMessageBox
        message="You have completed the&nbsp;session! Keep the pace with daily activities!"
        title='Keep Going!'
        display={celebration}
        celebrate={true}
        gatherFeedback={true}
        heartsEarned={user?.blocks[user.currentBlock]?.pointsType1}
        onClose={() => {
          setFinished(true);
          setCelebration(false);
          navigate("/")  
        }}
      />
    </Box>
  )
}

export default SessionChat;