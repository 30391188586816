import { createTheme } from '@mui/material/styles';
import { formLabelClasses } from "@mui/material";

export const MAX_WIDTH = "800px";
export const BOTTOM_NAVBAR_HEIGHT = "60px";

export const BACKGROUND_GRADIENT = "linear-gradient(180deg, #21263D 0%, #2D3453 100%)"
export const BACKGROUND_GRADIENT_TRANSPARENT_DO = "linear-gradient(180deg, #21263DD0 0%, #2D3453D0 100%)"
export const TOP_BACKGROUND_GRADIENT = "linear-gradient(180deg, #5866A3 0%, rgba(33, 38, 61, 0) 100px)"

const GaimControlTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#D7BAFF',
      contrastText: '#3E1F68',
    },
    secondary: {
      main: '#673AB7',
      contrastText: '#ffffff',
    },
    background: {
      default: '#21263D',
      paper: '#2A2A2A',
    },
    text: {
      primary: '#E9EAEB',
      secondary: '#E5E2E1',
      disabled: '#C4C7C8',
    },
    error: {
      main: '#D32F2F',
      contrastText: '#ffffff',
    },
    warning: {
      main: '#FB8C00',
      contrastText: '#ffffff',
    },
    success: {
      main: '#388E3C',
      contrastText: '#ffffff',
    },
    info: {
      main: '#0288D1',
      contrastText: '#ffffff',
    },
  },
  typography: {
    fontFamily: 'Poppins',
    h1: {
        color: '#ffffff',
    },
    h2: {
        color: '#ffffff',
    },
    h3: {
        color: '#ffffff',
    },
    h4: {
        color: '#ffffff',
    },
    h5: {
        color: '#ffffff',
    },
    h6: {
        color: '#ffffff',
    },
    subtitle1: {
        color: '#e5e2e1',
    },
    subtitle2: {
        color: '#e5e2e1',
    },
    body1: {
        color: '#e9eaeb',
        fontStyle: "normal",
        fontSize: "20px",
        lineHeight: "30px",
        letterSpacing: "0.25px",
    },
    body2: {
        color: '#c4c7c8',
        letterSpacing: "0.25px",
        fontSize: "16px",
    },
    button: {
        fontSize: '1rem',
        letterSpacing: '0.5px',
        color: 'white',
    },
    caption: {
        color: 'white',
    },
    overline: {
        color: 'white',
    }
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "white",
          [`&.${formLabelClasses.focused}`]: {
            color: "white",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '100px',
          textTransform: 'none',
        },
      },
    }
  }
})

export default GaimControlTheme;
