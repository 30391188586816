import { Box, CircularProgress, Icon, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import {
  Home as HomeIcon,
  Info as InfoIcon,
} from "@mui/icons-material";

const ICON_SIZE = '40px'

interface MyComponentProps {
  loading?: boolean;
  progress?: number;
  color?: string; 
  textColor?: string; 
  icon?: string;
  value?: string;
  label?: string;
  explanation?: string;
}

function StatsCardComponent(props: MyComponentProps) {
  return (
    <Stack height={'100%'}>{ props.loading !== true ? 
      <Stack
        bgcolor={props.color}
        borderRadius={'24px'}
        p={'19px'}
        spacing={1}
        height={'100%'}
      >
        <Stack direction={"row"} color={props.textColor} alignItems={'center'} fontSize={'40px'}>
          {
            props.progress === undefined ?
            props.icon ? <Icon sx={{marginRight: '10px'}} fontSize={'inherit'}>{props.icon}</Icon> : <HomeIcon /> :
            <Box
              sx={{
                position: 'relative',
                width: ICON_SIZE,
                height: ICON_SIZE,
                marginRight: '10px',
              }}
            >
              <CircularProgress
                variant='determinate'
                value={100}
                size={ICON_SIZE}
                thickness={12}
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  color: 'grey.400',
                }}
              />
              <CircularProgress
                variant='determinate'
                value={props.progress}
                size={ICON_SIZE}
                thickness={12}
                color='inherit'
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                }}
              />
            </Box>
          }
          {props.value && <Typography variant='h5' color={props.textColor}>{props.value}</Typography>}
          { props.explanation && <Box flexGrow={1}/> }
          { props.explanation && <Tooltip title={props.explanation}><InfoIcon sx={{alignSelf: 'start'}} /></Tooltip>}
        </Stack>
        <Typography color={props.textColor}>{props.label}</Typography>
      </Stack> :
      <Skeleton variant='rectangular' animation='wave' width={'100%'} sx={{borderRadius: '24px'}}>
        <Stack
          p={'19px'}
          justifyContent={'center'}
        >
          <Stack direction={"row"} fontSize={'40px'}>
            <HomeIcon fontSize={'inherit'}/>
            <Typography variant='h5' >10</Typography>
          </Stack>
          <Typography>Value</Typography>
        </Stack>
      </Skeleton>
    }</Stack>
  );
}

export default StatsCardComponent;
