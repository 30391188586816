// src/BouncingDots.js
import './bouncingdots.css';
import { Box } from '@mui/material';

const BouncingDots = () => (
  <Box m={1}>
    <div className="bouncing-dots">
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
    </div>
  </Box>
);

export default BouncingDots;
