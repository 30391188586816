import { Box, Link, Stack, Typography } from "@mui/material";
import AppbarMenu from "../components/appbarmenu";
import { MAX_WIDTH, TOP_BACKGROUND_GRADIENT } from "../theme/gaimcontrolTheme";

import {
  Phone as PhoneIcon,
  Language as LanguageIcon,
} from '@mui/icons-material';
import BottomNavBar from "../components/bottomnavbar";

function FirstAidPage() { 
  return (
    <Box
      alignItems={'center'}
      display={'flex'}
      flexDirection={'column'}
      flexGrow={1}
      sx={{
        height: '100%',
        width: '100%',
      }}
    >
      <AppbarMenu/>
      <Box
        display={"flex"}
        flexGrow={1}
        flexDirection={"column"}
        sx={{
          width: "100%",
          maxWidth: MAX_WIDTH,
          overflowY: 'auto',
        }}
      >
        <Box sx={{background: TOP_BACKGROUND_GRADIENT}}>
          <Stack spacing={2} m={1} mt={3} p={3} alignItems={"center"} sx={{ border: "1px solid #AE8DDD", borderRadius: "24px"}}>
            <Box width={"32px"} component="img" src="/logo_flat_white.svg"/>
            <Typography textAlign={"center"} variant="body1">
            If you feel distressed or are having suicidal thoughts, please use emergency services.
            We have listed some options below.
            </Typography>
          </Stack>
        </Box>
        <Stack spacing={2} m={1} p={3} alignItems={"center"} sx={{ border: "1px solid #AE8DDD", borderRadius: "24px"}}>
          <Typography textAlign={"center"} variant="body1">England</Typography>
          <Typography textAlign={"center"} variant="body1">
            <Link href="tel:111" sx={{display: "flex", alignItems: "center"}}>
              <PhoneIcon sx={{mr: 1}} />NHS Helpline: 111
            </Link>
          </Typography>
          <Typography textAlign={"center"} variant="body1">
            <Link href="tel:116123" sx={{display: "flex", alignItems: "center"}}>
              <PhoneIcon sx={{mr: 1}} />Samaritans: 116 123
            </Link>
          </Typography>
          <Typography textAlign={"center"} variant="body1">
            <Link href="https://www.nhs.uk/mental-health/feelings-symptoms-behaviours/behaviours/help-for-suicidal-thoughts/" target="_blank" rel="noopener" sx={{display: "flex", alignItems: "center"}}>
              <LanguageIcon sx={{mr: 1}} />NHS Resources
            </Link>
          </Typography>
        </Stack>
        <Stack spacing={2} m={1} mb={8} p={3} alignItems={"center"} sx={{ border: "1px solid #AE8DDD", borderRadius: "24px"}}>
          <Typography textAlign={"center"} variant="body1">Other countries</Typography>
          <Typography textAlign={"center"} variant="body1">
            <Link href="https://www.google.com/search?q=suicide+prevention+helpline" target="_blank" rel="noopener" sx={{display: "flex", alignItems: "center"}}>
              <LanguageIcon sx={{mr: 1}} />Search for local suicide prevention hotline
            </Link>
          </Typography>
        </Stack>
      </Box>
      <BottomNavBar value={2}/>
    </Box>
  )
}

export default FirstAidPage;
