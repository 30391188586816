import React from "react";
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField
} from "@mui/material";
import {
  Person as PersonIcon,
  AccountBalance as AccountBalanceIcon,
}from '@mui/icons-material';


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

interface DebtItemMessageBoxProps {
    open: boolean;
    delete?: boolean;
    type?: 'person' | 'organization' | undefined;
    creditor?: string | undefined;
    amount?: number | undefined;
    onDelete?: () => void;
    onCancel?: () => void;
    onSave?: () => void;
    onSelectType?: ( type: 'person' | 'organization' ) => void;
    onChangeCreditor?: ( creditor: string ) => void;
    onChangeAmount?: ( amount: number ) => void;
}

function DebtItemMessageBox( props: DebtItemMessageBoxProps ) {
  return (
    <Dialog
      TransitionComponent={Transition}
      open={props.open}
      PaperProps={{
        sx: { borderRadius: "28px", padding: "5px"},
      }}
    >
      <DialogTitle>Add a new debt</DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
        <Stack direction={"row"}>
          <Button
            variant={props.type === 'person' ? 'contained' : 'outlined'}
            sx={{borderRadius: "100px 0px 0px 100px", width: "50%", paddingX: "30px"}}
            startIcon={<PersonIcon />}
            onClick={() => props.onSelectType && props.onSelectType('person')}
          >
            Person
          </Button>
          <Button
            variant={props.type === 'organization' ? 'contained' : 'outlined'}
            sx={{borderRadius: "0px 100px 100px 0px", width: "50%", paddingX: "30px"}}
            startIcon={<AccountBalanceIcon />}
            onClick={() => props.onSelectType && props.onSelectType('organization')}
          >
            Organisation
          </Button>
        </Stack>
        <TextField
          label="Name"
          value={props.creditor}
          onChange={(e) => props.onChangeCreditor && props.onChangeCreditor(e.target.value)}
        />
        <TextField
          type="number"
          label="Amount"
          value={props.amount}
          onChange={(e) => props.onChangeAmount && props.onChangeAmount(Math.max(parseInt(e.target.value), 0))}
        />
        </Stack>
      </DialogContent>
      <DialogActions>
        {props.delete && <Button color={"error"} onClick={props.onDelete}>Delete</Button>}
        <Button onClick={props.onCancel}>Cancel</Button>
        <Button disabled={!props.creditor || !props.amount || !props.type} onClick={props.onSave}>Save</Button>
      </DialogActions>
    </Dialog>
  )
}

export default DebtItemMessageBox;