import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
  Zoom
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import ReactGA from "react-ga4";
import axios from 'axios';

import { BOTTOM_NAVBAR_HEIGHT, MAX_WIDTH, TOP_BACKGROUND_GRADIENT } from '../theme/gaimcontrolTheme';
import AppbarMenu from '../components/appbarmenu';
import BottomNavBar from '../components/bottomnavbar';
import ConfettiExplosion from 'react-confetti-explosion';
import { flushSync } from 'react-dom';

const colors = {
  gray: 'linear-gradient(120deg, #aaaf 30%, #aaad 50%, #aaaf 70%);',
  green: 'linear-gradient(120deg, #0d0f 30%, #0d0d 50%, #0d0f 70%);',
  yellow: 'linear-gradient(120deg, #dd0f 30%, #dd0d 50%, #dd0f 70%);',
};

interface WordleAPIResponse {
  word: string;
}

const WordleGame = () => {
  const [guesses, setGuesses] = useState<string[]>([]);
  const [currentGuess, setCurrentGuess] = useState('');
  const [isGameOver, setIsGameOver] = useState(false);
  const [answer, setAnswer] = useState('');
  const [processing, setProcessing] = useState<boolean>(false)
  const [showConfetti, setShowConfetti] = useState<boolean>(false)

  useEffect(() => {
    setProcessing(true)
    axios.get<WordleAPIResponse>(process.env.REACT_APP_CHAT_SERVER_URL + "/wordle", {
      headers: {
          "Authorization": process.env.REACT_APP_API_TOKEN,
      },
      params: {
        userName: 'response.data.name'
      }, 
    }).then( response => {
      setAnswer(response.data.word.toUpperCase());
    }).catch( error => {
      console.log(error);
    }).finally(() => {
      setProcessing(false)
    })
  }, []);

  const handleGuess = () => {
    ReactGA.event({
      category: "Wordle",
      action: "Guess attempt",
      label: currentGuess,
    })

    const newGuesses = [currentGuess, ...guesses];
    setGuesses(newGuesses);
    setCurrentGuess('');

    if (currentGuess === answer)  {
      ReactGA.event({
        category: "Wordle",
        action: "Guess success",
        label: newGuesses.length.toString(),
      })
      setShowConfetti(true);
      setIsGameOver(true);
      setTimeout(() => setShowConfetti(false), 3000);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if ((e.key === 'Enter') && (currentGuess.length === 5)) {
      handleGuess();
    }
  };

  const getBackgroundColor = (letter: string, index: number) => {
    console.log(`${letter}:${index} of ${answer[index]}`)

    if (index < 0) return colors.gray;

    if (letter === answer[index]) {
      console.log('green')
      return colors.green;
    }

    if (answer.includes(letter)) {
      console.log('yellow')
      return colors.yellow;
    }

    console.log('gray')
    return colors.gray;
  };

  return (
    <Stack
      spacing={0}
      alignSelf={'center'}
      width={'100%'}
      flexGrow={1}
      maxWidth={MAX_WIDTH}
      alignItems={'center'}
      sx={{
        overflowY: 'hidden',
      }}
    >
      <AppbarMenu />
      <Stack
        width={'100%'}
        sx={{background: TOP_BACKGROUND_GRADIENT, boxSizing: 'border-box', overflowY: 'auto'}}
        alignItems={'center'}
        flexGrow={1}
        spacing={1}
        p={2}
      >
        <Typography variant='h5'>The Daily Word</Typography>
        {isGameOver ?
          (<Typography>Congratulations! You won!</Typography>) :
          (<Typography>Guess a 5-letter word</Typography>)}
        <TextField
          id="outlined-basic"
          disabled={isGameOver}
          placeholder='Your guess'
          onChange={(e) => setCurrentGuess(e.target.value.replace(/[^a-zA-Z]/g, '').substring(0, 5).toUpperCase())}
          value={currentGuess}
          onKeyDown={handleKeyPress}
          inputProps={{min: 0, style: { textAlign: 'center' }}}
        />

        {/* Confetti is wrapped in a stack with additional box to keep layout
        from changing when mounting/unmounting it */}
        <Stack direction={'row'}>
          {showConfetti && <ConfettiExplosion particleCount={600} zIndex={2000}/>}
          <Box/>
        </Stack>
        
        <Stack spacing={1} m={2}>
          {guesses.map((guess) => (
            <Zoom in key={guess} timeout={250}>
              <Grid container spacing={1} columns={5} width={'100%'}>
                {guess.split('').map((letter, letterIndex) => (
                  <Grid
                    item
                    key={letterIndex}
                    xs={1}
                  >
                    <Typography
                      variant='h6'
                      textAlign={'center'}
                      sx={{
                        backgroundImage: getBackgroundColor(letter, letterIndex),
                        borderRadius: '5px',
                        paddingX: 2,
                        paddingY: 1,
                        color: '#333',
                      }}
                    >
                      {letter.toUpperCase()}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Zoom>
          ))}

          <Stack display={'flex'} justifyContent={'center'} alignItems={'center'} pt={4}>
            <Typography variant='h4' gutterBottom width={'100%'}>The rules</Typography>
            <Typography variant='body1' gutterBottom width={'100%'}>Try guessing the word: type a 5-letter word and hit Enter. The check shows:</Typography>
            <Typography variant='body1' width={'100%'}>* Green: Correct letter and position.</Typography>
            <Typography variant='body1' width={'100%'}>* Yellow: Correct letter, wrong position.</Typography>
            <Typography variant='body1' gutterBottom width={'100%'}>* Gray: Wrong letter.</Typography>
            <Typography variant='body1' gutterBottom width={'100%'}>Here is an example for the word DREAM:</Typography>
            <Grid container spacing={1} columns={5} width={'100%'}>
                <Grid item xs={1}>
                  <Typography
                    variant='h6'
                    textAlign={'center'}
                    sx={{ backgroundImage: colors.yellow, borderRadius: '5px', paddingX: 2, paddingY: 1, color: '#333' }}
                  >A</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    variant='h6'
                    textAlign={'center'}
                    sx={{ backgroundImage: colors.gray, borderRadius: '5px', paddingX: 2, paddingY: 1, color: '#333' }}
                  >L</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    variant='h6'
                    textAlign={'center'}
                    sx={{ backgroundImage: colors.yellow, borderRadius: '5px', paddingX: 2, paddingY: 1, color: '#333' }}
                  >A</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    variant='h6'
                    textAlign={'center'}
                    sx={{ backgroundImage: colors.yellow, borderRadius: '5px', paddingX: 2, paddingY: 1, color: '#333' }}
                  >R</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    variant='h6'
                    textAlign={'center'}
                    sx={{ backgroundImage: colors.green, borderRadius: '5px', paddingX: 2, paddingY: 1, color: '#333' }}
                  >M</Typography>
                </Grid>
            </Grid>
          </Stack>
          <Box height={BOTTOM_NAVBAR_HEIGHT} />
        </Stack>
      </Stack>

      <Backdrop open={processing}>
        <CircularProgress />
      </Backdrop>
      <BottomNavBar value={1} />
    </Stack>
  );
};

export default WordleGame;
